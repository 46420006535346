import React, {useEffect, useState} from 'react';
import {
  Row,
  Col,
  Form,
  Select,
  Button,
  DatePicker,
  Tag,
  Upload,
  message,
  Alert,
  Card,
  notification,
  Checkbox,
  Dropdown, Menu, Collapse, Modal, Input, Popover, Tooltip
} from 'antd';


import {CollapseProps} from "antd/lib/collapse/Collapse";
import {Contact, ContactGroup, WhatsappCampaign} from "models";
// import {CampaignMessageFormModel, CampaignScheduleFormModel, WhatsappCampaignScheduleFormModel} from 'models/forms';
import TimeZone from 'configs/TimeZones.json';
import {
  getFileHeadersService,
  listContactService,
  searchContactsService,
  uploadFileService
} from 'services/dashboard/ContactService';
import {TweenOneGroup} from 'rc-tween-one';
import {
  CaretRightOutlined, ClearOutlined,
  CloseOutlined,
  DownCircleOutlined,
  InfoCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import TextArea from "antd/lib/input/TextArea";

// import { CountryPhoneInputValue } from 'antd-country-phone-input';
// import en from 'world_countries_lists/data/countries/en/world.json';
// import { findCountryCode, findCountryDialCode } from 'utils/helper';
import {UploadFile} from 'antd/lib/upload/interface';
import {TweenOneContactGroup} from 'models/TweenOneContactGroup';
// import moment from 'antd/node_modules/moment';
import antdMoment from 'antd/node_modules/moment'; // Import moment from Ant Design
import moment from 'moment-timezone'; // Import moment from moment-timezone
import {getMappingFromGroupService, listGroupService} from 'services/dashboard/ContactGroupService';
import CampaignContactPicker from '../CampaignContactPicker';
import CampaignGroupPicker from '../CampaignGroupPicker';
import CampaignFileMapping from "../../../sms/campaign/CampaignFileMapping";
import ChatPreviewPage from "../../WhatsappTemplate/WhatsappTemplatePreview";
import {WhatsappCampaignMessageFormModel} from "../../../../models/forms";

// import {findCountryDialCode} from "../../../../utils/helper";

const {Panel} = Collapse;
// const {TextArea} = Input;
export interface CampaignScheduleTabProps {
  whatsappCampaign?: WhatsappCampaign;
  messageFormModel?: WhatsappCampaignMessageFormModel;
  selectedTemplate?: any;
  buttonTextValues?: any;
  buttonPhoneTextValues?: any;
  buttonUrlTextValues?: any;
  buttonCopyCodeTextValues?: any;
  onClickNext: (scheduleFormModel: {
    filepath: string;
    scheduled_tz: string;
    recipients: string[];
    file_mapping: any;
    allow_duplicate: boolean;
    groups: number[] | undefined;
    scheduled_at: string | undefined;
    // eslint-disable-next-line no-use-before-define
    filtering: FilterData[];
    contacts: number[] | undefined
  }) => void;
  onClickPrev?: () => void;
  schedule?: boolean;
  errorMessage?: string;
  errorMessageValidation?: string;
  inputDirection?: any;
  originator?: any;
};

interface FilterData {
  field: string;
  operator: string;
  value: string;
}

interface FieldMapping {
  [key: string]: string;
}


const CampaignScheduleTab = (props: CampaignScheduleTabProps) => {
  const {
    whatsappCampaign,
    messageFormModel,
    onClickNext,
    onClickPrev,
    schedule,
    errorMessage,
    errorMessageValidation,
    selectedTemplate,
    buttonTextValues,
    buttonPhoneTextValues,
    buttonUrlTextValues,
    buttonCopyCodeTextValues, inputDirection, originator

  } = props;
  const [scheduleForm] = Form.useForm();
  const [filteringForm] = Form.useForm();
  const [uploadingFile, setUploadingFile] = useState(false);
  const [requiredTimezone, setRequiredTimezone] = useState(false);
  // const [disableRecipientFieldValidation, setDisableRecipientFieldValidation] = useState(true);
  const [selectedRecipients, setSelectedRecipients] = useState<string[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const [selectedContactGroups, setSelectedContactGroups] = useState<ContactGroup[]>([]);
  const [selectedRecipientFile, setSelectedRecipientFile] = useState<string[]>([]);
  const [selectedRecipientFilePath, setSelectedRecipientFilePath] = useState<string>("");

  const [combinedRecipients, setCombinedRecipients] = useState<TweenOneContactGroup[]>([]);

  const [defaultFileList, setDefaultFileList] = useState<UploadFile[]>([]);
  const [showContactPicker, setShowContactPicker] = useState(false);
  const [showRecipientsAdder, setShowRecipientsAdder] = useState(false);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [contactsTotal, setContactsTotal] = useState(0);
  const [contactLoading, setContactLoading] = useState(false);
  // const [contactsSearchedText, setContactsSearchedText] = useState<string | undefined>();
  const [contactsPageSize, setcontactsPageSize] = useState(10);

  const [showContactGroupPicker, setShowContactGroupPicker] = useState(false);
  const [contactGroups, setContactGroups] = useState<ContactGroup[]>([]);
  const [contactGroupLoading, setContactGroupLoading] = useState(false);
  const [scheduledTZ, setSchedulesTZ] = useState<string>("");
  const [cardHeight, setCardHeight] = useState('80px');
  const [isFileMapping, setIsFileMapping] = useState(false);
  const [fileMapping, setFileMapping] = useState<any>();
  const [headerList, setHeaderList] = useState<string[]>([]);
  const [allowDuplicate, setAllowDuplicate] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [formData, setFormData] = useState({});
  const [filteringData, setFilteringData] = useState<FilterData[]>([]);
  const [isFiltering, setIsFiltering] = useState(false);
  const [filterFieldMappingValue, setFilterFieldMappingValue] = useState<FieldMapping>({});



  const fetchContactGroups = async () => {
    setContactGroupLoading(true);
    try {
      const response = await listGroupService();
      setContactGroups(response);
      setContactGroupLoading(false);
    } catch (error) {
      setContactGroups([]);
      setContactGroupLoading(false);
    }
    console.log(defaultFileList);
  };

  const onCloseContactGroupPicker = () => {
    setShowContactGroupPicker(false);
  };

  const onClickAddContactGroupButton = async () => {
    setShowContactGroupPicker(true);
    await fetchContactGroups();
  };

  const onCloseContactPicker = () => {
    setShowContactPicker(false);
    setContacts([]);
    setContactsTotal(0);
  };

  const fetchContacts = async (page: number = 1, size: number = 10) => {
    setContactLoading(true);
    try {
      const response = await listContactService({page, size});
      const finalContacts =response.items.map(cont => ({
        ...cont,
        row_selection_disabled: (cont.blacklist === true || ((cont.contact_whatsapp_opt_out ?? [])?.length>0 && cont.contact_whatsapp_opt_out?.some(item => item.whatsapp_number === originator?.number))),
      }));
      setContacts(finalContacts);
      setContactsTotal(response.total);
    } catch (error) {
      setContacts([]);
      setContactsTotal(0);
    } finally {
      setContactLoading(false);
    }
  };
  const searchContacts = async (searchText: string, page: number = 1, size: number = 10) => {
    setContactLoading(true);
    try {
      const response = await searchContactsService({searchkey: searchText, page, size});
      setContacts(response.items);
      setContactsTotal(response.total);
    } catch (error) {
      setContacts([]);
      setContactsTotal(0);
    } finally {
      setContactLoading(false);
    }
  };
  const onContactPaginate = async (page: number, size: number, searchText?: string) => {
    setContactLoading(true);
    // setContactsSearchedText(searchText);
    setcontactsPageSize(size);
    if (searchText && searchText.length > 0) {
      await searchContacts(searchText, page, size);
    } else {
      await fetchContacts(page, size);
    }
  };


  const onClickAddContactButton = async () => {
    setShowContactPicker(true);
    await onContactPaginate(1, contactsPageSize);

  };
  const fileUploadCustomRequest = (options: any) => {
    const isCSV = options.file.type;
    if (isCSV === "text/csv") {
      setUploadingFile(true);
      const data = new FormData();
      data.append('file', options.file);
      uploadFileService(options.file)
        .then(response => {
            if (response.filepath !== 'uploaded file format is not supported!') {
              options.onSuccess(response, options.file);
            } else {
              message.error(`${options.file} file type is not supported.`);
            }
          }
        ).catch(error => {
      })
        .finally(() => setUploadingFile(false));
    } else {
      message.error(`${options.file} file type is not supported.`);
    }
  };

  // const pushToRecipients = (value: string) => {
  //   if (value && value.length > 0) {
  //     const recipintData = [...selectedRecipients];
  //     recipintData.push(value);
  //     setSelectedRecipients(recipintData);
  //     const combainedData = [...combinedRecipients];
  //     combainedData.push({id: undefined, type: 'recipient', key: value});
  //     setCombinedRecipients(combainedData);
  //     scheduleForm.setFieldsValue({recipients: ''});
  //   }
  // };

  const pushToRecipients = (value: string) => {
    if (value && value.length > 0) {
      setSelectedRecipients(prevSelectedRecipients => [...prevSelectedRecipients, value]);
      setCombinedRecipients(prevCombinedRecipients => [...prevCombinedRecipients, { id: undefined, type: 'recipient', key: value }]);
      scheduleForm.setFieldsValue({ recipients: '' });
    }
  };

  const onClickSubmitForm = async () => {
    try {
      // setDisableRecipientFieldValidation(false);
      await scheduleForm.validateFields(); // Validate form fields
      await scheduleForm.submit(); // Submit form
    } catch (error) {
      // console.error('Error submitting form:', error);
      // Handle any errors that occur during form submission
    }
  };


  const getISOString = (dateValue: Date | undefined) => {
    if (dateValue) {
      const d = new Date(dateValue);
      let month = (d.getMonth() + 1).toString();
      let day = d.getDate().toString();
      const year = d.getFullYear();
      let hours = d.getHours().toString();
      let minutes = d.getMinutes().toString();
      let seconds = d.getSeconds().toString();
      month = month.length < 2 ? `0${month}` : month;
      day = day.length < 2 ? `0${day}` : day;
      hours = hours.length < 2 ? `0${hours}` : hours;
      minutes = minutes.length < 2 ? `0${minutes}` : minutes;
      seconds = seconds.length < 2 ? `0${seconds}` : seconds;
      return (`${[year, month, day].join('-')} ${[hours, minutes, seconds].join(':')}.000`);
    }
    return undefined;
  };

  const onFinishScheduleForm = (values: {
    recipientFile: string;
    campaignSchedule?: Date;

  }) => {
    onClickNext?.({
      recipients: selectedRecipients,
      file_mapping: fileMapping,
      allow_duplicate: allowDuplicate,
      contacts: selectedContacts?.map(x => x.id),
      groups: selectedContactGroups?.map(x => x.id),
      filepath: values.recipientFile ? values.recipientFile : selectedRecipientFilePath,
      scheduled_tz: scheduledTZ,
      scheduled_at: getISOString(values.campaignSchedule), // ?.replace('Z', ''),
      filtering: filteringData
    });
  };

  const onErrorGetFileHeaders = (showNotification?: boolean) => {
    if (showNotification) {
      const notificationParam = {
        message: "Failed to process file",
        description: "",
      };
      notification.error(notificationParam);
    }
  };

  const onErrorGetFileNotFound = () => {
    const notificationParam = {
      message: "Please upload a valid file!",
      description: "",
    };
    notification.error(notificationParam);
  };

  const getFileHeaders = async (filePath: string, delimite: string) => {
    try {
      const response = await getFileHeadersService({
        filepath: filePath,
        delimiter: delimite
      });
      if (response.filepath === filePath
        && response.headerlist && response.headerlist.length > 0) {
        setHeaderList(response.headerlist);
        setIsFileMapping(true);
      } else {
        onErrorGetFileHeaders(true);
      }
    } catch (error: any) {
      scheduleForm.setFieldsValue({recipientFile: null});
      setSelectedRecipientFilePath('');
      setHeaderList([]);
      setSelectedRecipientFile([]);
      setCombinedRecipients([]);
      if (error.code === "FILE_NOT_EXISTS") {
        onErrorGetFileNotFound();
      } else {
        onErrorGetFileHeaders(true);
      }
    }
  };

  const pushRecipientFileTags = (fileName: string) => {
    const contactTags = selectedContacts.map(c => ({id: c.id, type: 'contact', key: `Contact: ${c.number}`}));
    const recipientTags = selectedRecipients.map(r => ({type: 'recipient', key: r}));
    const contactGroupTags = selectedContactGroups.map(c => ({id: c.id, type: 'group', key: `Group: ${c.name}`}));
    const recipientFileTags = [{type: "recipientFile", key: `Recipient File: ${fileName}`}];
    setCombinedRecipients([...recipientTags, ...contactTags, ...contactGroupTags, ...recipientFileTags]);
  };

  const onChangeFile = (info: any) => {
    const {status, response} = info.file;
    if (status === "done") {
      getFileHeaders(response.filepath, ",");
      message.success(`${info.file.name} file uploaded successfully.`);
      scheduleForm.setFieldsValue({recipientFile: response.filepath});
      setSelectedRecipientFilePath(response.filepath);
      setSelectedRecipientFile([info.file.name]);
      pushRecipientFileTags(info.file.name);
    } else if (status === "error") {
      scheduleForm.setFieldsValue({recipientFile: ''});
      message.error(`${info.file.name} file upload failed.`);
    } else if (status === "removed") {
      scheduleForm.setFieldsValue({recipientFile: ''});
    }
  };


  const onClickAddRecipient = async () => {
    setShowRecipientsAdder(false);
    try {
      const response = await scheduleForm?.validateFields(['recipients']);
      if (response && response.recipients) {
        const {phone } = response.recipients;
        const recipients = phone.split(/[\n,]/).filter((recipient: any) => recipient.trim() !== '');
        recipients.forEach((recipient: any) => {
          const number = recipient?.startsWith(
            `+`
          )
            ? recipient
            : `+${recipient}`;
          pushToRecipients(number);
        });
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const onClickAddRecipientsButton = async () => {
    setShowRecipientsAdder(true);
  };
  // eslint-disable-next-line react/no-unstable-nested-components
  const RecipientDropdown = () => {
    const [visible, setVisible] = useState(false);

    const handleMenuClick = (key: any) => {
      setVisible(false);
      switch (key) {
        case 'addRecipients':
          onClickAddRecipientsButton();
          break;
        case 'addContact':
          onClickAddContactButton();
          break;
        case 'addGroup':
          onClickAddContactGroupButton();
          break;
        case 'importCSV':
          // onChangeFile();
          break;
        default:
          break;
      }
    };

    const menu = (
      <Menu onClick={(e) => handleMenuClick(e.key)}>
        {selectedRecipients.length <= 1000 && (
          <Menu.Item key="addRecipients">Copy Paste</Menu.Item> )}
        <Menu.Item key="addContact">Add from contacts</Menu.Item>
        <Menu.Item key="addGroup">Add from group</Menu.Item>
        <Menu.Item key="importCSV">
          <Upload
            name="file"
            accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/plain"
            maxCount={1}
            customRequest={fileUploadCustomRequest}
            onChange={onChangeFile}
            showUploadList={false}
          >
            Import from CSV
          </Upload>
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu} visible={visible} onVisibleChange={setVisible}>
        <Button className="bg-gray-lighter px-lg-2 px-md-2 px-sm-5 px-xs-5" style={{...(window.innerWidth >= 1440 && {paddingRight: '20px', paddingLeft: '20px'})}}>
          + Recipient
        </Button>
      </Dropdown>
    );
  };
  // Note section content
  const fileFormatInstructions = (
    <div className="mb-2">
      {/* <h5>Import File Format Instructions:</h5> */}
      <p><b className="font-weight-bolder"><InfoCircleOutlined/></b> Any opt-out numbers provided will be excluded from
        the list during processing.</p>
      <p><b className="font-weight-bolder"><InfoCircleOutlined/></b> Kindly upload CSV with column header and
        recipient&apos;s phone number with country code.</p>
      <p><DownCircleOutlined/> Download a sample CSV import file from {' '}
        <a download="contact_data.csv" href="/data/contact_data.csv">
          <b>here</b>
        </a>
        . You can include additional columns such as address, state, mobile, etc., and map these columns to the
        corresponding contact fields during the import process.
      </p>
    </div>
  );

  const pushTags = (recipientList: string[], contactsList: Contact[], groupList: ContactGroup[], newRecipientFile: string[]) => {
    const contactTags = contactsList.map(c => ({
      id: c.id,
      type: 'contact',
      key: `Contact: ${(c.number && c.number.length > 0) ? c.number : c.id}`
    }));
    const recipientTags = recipientList.map(r => ({type: 'recipient', key: r}));
    const contactGroupTags = groupList.map(c => ({
      id: c.id,
      type: 'group',
      key: `Group: ${(c.name && c.name.length > 0) ? c.name : c.id}`
    }));
    const recipientFileTags = newRecipientFile.map(r => ({type: 'recipientFile', key: `Recipient File: ${r}`}));
    setCombinedRecipients([...recipientTags, ...contactTags, ...contactGroupTags, ...recipientFileTags]);
  };
  const pushContactTags = (records: Contact[]) => {
    const contactTags = records.map(c => ({id: c.id, type: 'contact', key: `Contact: ${c.number}`}));
    const recipientTags = selectedRecipients.map(r => ({type: 'recipient', key: r}));
    const contactGroupTags = selectedContactGroups.map(c => ({id: c.id, type: 'group', key: `Group: ${c.name}`}));
    const recipientFileTags = selectedRecipientFile.map(r => ({type: 'recipientFile', key: `Recipient File: ${r}`}));
    setCombinedRecipients([...recipientTags, ...contactTags, ...contactGroupTags, ...recipientFileTags]);
  };
  const pushContactGroupTags = (records: ContactGroup[]) => {
    const contactTags = selectedContacts.map(c => ({id: c.id, type: 'contact', key: `Contact: ${c.number}`}));
    const recipientTags = selectedRecipients.map(r => ({type: 'recipient', key: r}));
    const contactGroupTags = records.map(c => ({id: c.id, type: 'group', key: `Group: ${c.name}`}));
    const recipientFileTags = selectedRecipientFile.map(r => ({type: 'recipientFile', key: `Recipient File: ${r}`}));
    setCombinedRecipients([...recipientTags, ...contactTags, ...contactGroupTags, ...recipientFileTags]);
  };

  // const pushRecipientTags = (records: string[]) => {
  //   const contactTags = selectedContacts.map(c => ({ id: c.id, type: 'contact', key: `Contact: ${c.number}` }));
  //   const contactGroupTags = selectedContactGroups.map(c => ({ id: c.id, type: 'group', key: `Group: ${c.name}` }));
  //   const recipientTags = records.map(r => ({ type: 'recipient', key: r }));
  //   setCombinedRecipients([...recipientTags, ...contactTags, ...contactGroupTags]);
  // };
  const removeCombinedRecipient = (type: string, id: number) => {
    const combainedData = combinedRecipients.filter(c => c.type !== type || (c.type === type && c.id !== id));
    setCombinedRecipients(combainedData);
  };
  const removeCombinedRecipientByKey = (type: string, key: string) => {
    const combainedData = combinedRecipients.filter(c => c.type !== type || (c.type === type && c.key !== key));
    setCombinedRecipients(combainedData);
  };

  const removeCombinedRecipientFileByKey = (type: string) => {
    scheduleForm.setFieldsValue({recipientFile: null});
    const combainedData = combinedRecipients.filter(c => c.type !== type);
    setCombinedRecipients(combainedData);
  };

  const handleRemoveRecipients = (removedTag: TweenOneContactGroup) => {
    if (removedTag.type === 'recipient') {
      const newRecipients = selectedRecipients.filter(tag => tag !== removedTag.key);
      // pushRecipientTags(newRecipients);
      setSelectedRecipients(newRecipients);
      removeCombinedRecipientByKey('recipient', removedTag.key);
    } else if (removedTag.type === 'contact') {
      const newContacts = selectedContacts.filter(c => c.id !== removedTag.id);
      // pushContactTags(newContacts);
      setSelectedContacts(newContacts);
      removeCombinedRecipient('contact', removedTag.id ?? 0);
    } else if (removedTag.type === 'group') {
      const newContactGroups = selectedContactGroups.filter(c => c.id !== removedTag.id);
      // pushContactGroupTags(newContactGroups);
      setSelectedContactGroups(newContactGroups);
      removeCombinedRecipient('group', removedTag.id ?? 0);
    } else if (removedTag.type === "recipientFile") {
      setSelectedRecipientFile([]);
      removeCombinedRecipientFileByKey('recipientFile');
      setSelectedRecipientFilePath('');
    }
  };

  const handleRemoveAll = () => {
    // Clear selected recipients
    setSelectedRecipients([]);
    // Clear selected contacts
    setSelectedContacts([]);
    // Clear selected contact groups
    setSelectedContactGroups([]);
    // Clear selected recipient files
    setSelectedRecipientFile([]);
    // Clear combined recipients
    setCombinedRecipients([]);
    setSelectedRecipientFilePath('');
  };


  const onSelectContacts = (records: Contact[]) => {
    const existingSelectedContacts = selectedContacts.filter(x => contacts.findIndex(y => y.id === x.id) < 0);
    const newContacts = [...existingSelectedContacts, ...records];
    setSelectedContacts(newContacts);
    pushContactTags(newContacts);
    setShowContactPicker(false);
  };
  const onSelectContactGroups = (records: ContactGroup[]) => {
    const existingSelectedContactGroupss = selectedContactGroups.filter(x => contactGroups.findIndex(y => y.id === x.id) < 0);
    const newContactGroups = [...existingSelectedContactGroupss, ...records];
    setSelectedContactGroups(newContactGroups);
    pushContactGroupTags(newContactGroups);
    setShowContactGroupPicker(false);
    setFilterFieldMappingValue({});
  };

  const onClickFileMappingSave = (mapping: any) => {
    setFileMapping(mapping);
    setFilterFieldMappingValue(mapping);
    setIsFileMapping(false);
  };

  const onClickCloseMappingModal = () => {
    setIsFileMapping(false);
    scheduleForm.setFieldsValue({recipientFile: null});
    setSelectedRecipientFilePath('');
    setHeaderList([]);
    setSelectedRecipientFile([]);
    removeCombinedRecipientFileByKey('recipientFile');
  };

  useEffect(() => {
    if (whatsappCampaign) {
      scheduleForm?.setFieldsValue({
        recipientFile: whatsappCampaign.recipients_file, timezone: whatsappCampaign.scheduled_tz ?? ''
      });
      if (whatsappCampaign.scheduled_at)
        scheduleForm?.setFieldsValue({whatsappCampaignSchedule: antdMoment(whatsappCampaign.scheduled_at.replace('+00:00', '.000'))});

      if (whatsappCampaign.recipients_file)
        setDefaultFileList([{uid: '', name: whatsappCampaign.recipients_file, url: whatsappCampaign.recipients_file}]);
      const newRecipients = whatsappCampaign.recipients ?? [];
      const newRecipientFile = [whatsappCampaign.recipients_file];
      const newGroups = whatsappCampaign.contact_groups?.map((c: any) => ({
        id: c,
        name: '',
        blacklist: false,
        total_contacts_count: 0,
        total_blacklisted_contacts_count: 0,
        country_wise_count: {}
      })) ?? [];
      const newContacts = whatsappCampaign.contacts?.map((c: any) => ({id: c, groups: []})) ?? [];
      setSelectedRecipients(newRecipients);
      setSelectedContactGroups(newGroups);
      setSelectedContacts(newContacts);
      pushTags(newRecipients, newContacts, newGroups, newRecipientFile);
    }

    if (combinedRecipients.length > 0) {
      setCardHeight('auto');
    } else {
      setCardHeight('100px');
    }

    if (selectedContactGroups && selectedContactGroups.length === 1) {
      try {
        Promise.all(
          selectedContactGroups.map(async (groupId: any) => {
            const response = await getMappingFromGroupService(groupId.id);
            if (response && response.mapping) {
              setFilterFieldMappingValue(response.mapping);
            }
          })
        );
      } catch (error) {
        setFilterFieldMappingValue({});
      }
    }

  }, [whatsappCampaign, combinedRecipients, selectedTemplate]);

  const expandIcon = (panelProps: CollapseProps) => {
    const {activeKey} = panelProps;
    return <CaretRightOutlined rotate={activeKey ? 90 : 0}/>;
  };

  const PERSONALIZATION_FIELDS = [
    { text: 'Var 1', value: 'var1' },
    { text: 'Var 2', value: 'var2' },
    { text: 'Var 3', value: 'var3' },
    { text: 'Var 4', value: 'var4' },
    { text: 'Var 5', value: 'var5' },
  ];

  const OPERATORS = [
    { text: '=', value: '=' },
    { text: '!=', value: '!=' },
    { text: '>', value: '>' },
    { text: '<', value: '<' },
  ];


  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = (value: any) => {
    if (Object.keys(value).length > 0) {
      setFilteringData([...filteringData, value]);
      setIsFiltering(true);
    }
    filteringForm.resetFields(); // Reset form fields
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    filteringForm.resetFields(); // Reset form fields
    setIsModalVisible(false);
  };

  // const handleOkRecipientsModal = async () => {
  //   setShowRecipientsAdder(false);
  //   await onClickAddRecipient();
  // };

  const handleCancelRecipientsModal = () => {
    setShowRecipientsAdder(false);
  };


  const [phoneNumbers, setPhoneNumbers] = useState('');

  const handlePhoneNumbersChange = (e: any) => {
    setPhoneNumbers(e.target.value);
  };

  const validatePhoneNumbers = (value: any) => {
    const numbers = phoneNumbers.split(/[\n,]+/).filter(Boolean); // Split numbers by newline or comma and filter out empty strings
    return numbers.length <= 1000;
  };

  const chatContainerStyle: React.CSSProperties = {
    margin: '20px auto',
    padding: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    background: `url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png')`, // Replace with your image URL
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
  };


  return (
    <>
      <Form
        form={scheduleForm}
        name="messageForm"
        layout="vertical"
        size="small"
        onFinish={onFinishScheduleForm}>
        <Row gutter={16}>
          <Col lg={1} xl={1}/>
          <Col xs={24} sm={24} md={24} lg={15} xl={15}>
            <Row gutter={16}>
              {/* Recipient List */}
              <Col xs={24} sm={24} md={24} lg={24}>
                { originator && (
                  <Alert
                    description={`You have messaging limit of ${originator?.remaining_messaging_limit} left today. ie, you can only send messages to ${originator?.remaining_messaging_limit} unique recipients numbers today`}
                    type="info"
                    showIcon
                    closable
                  />
                )}
                <br/>
                <h4>Recipients List</h4>
                <Card style={{marginBottom: '10px', height: cardHeight, overflow: 'auto'}}>
                  <div style={{maxHeight: '200px', overflowY: 'auto'}}>
                    <TweenOneGroup
                      enter={{
                        scale: 0.8,
                        opacity: 0,
                        type: 'from',
                        duration: 100,
                      }}
                      leave={{opacity: 0, width: 0, scale: 0, duration: 200}}
                      appear={false}
                    >
                      {combinedRecipients.map((r) => (
                        <span
                          key={r.key}
                          style={{display: 'inline-block'}}
                          className="mb-2 font-size-lg font-weight-bold text-muted"
                        >
            <Tag
              closable
              onClose={(e) => {
                e.preventDefault();
                handleRemoveRecipients(r);
              }}
            >
              {r.key}
            </Tag>
          </span>
                      ))}
                    </TweenOneGroup>
                  </div>
                  {schedule && combinedRecipients.length === 0 && (
                    <Alert message={errorMessage} type="error"/>
                  )}
                  {combinedRecipients.length !== 0 && (
                    <>
                      {errorMessageValidation && (
                        <>
                          {!isFiltering && (
                            <Alert className="text-left" message={errorMessageValidation} type="error"/>)}
                          {isFiltering && (
                            <Alert className="text-left" message="At least one contact must match the filtering criteria to proceed" type="error"/>)}
                        </>
                      )}

                      {/* <div className="text-right"> */}
                      {/*  <Tag className="m-0 text-right" color="geekblue">{combinedRecipients.length} Recipients</Tag> */}
                      {/* </div> */}

                      <div className="text-right">
                        <Tooltip title="Remove All">
                          <Button onClick={handleRemoveAll}>
                            <ClearOutlined/>
                          </Button>
                        </Tooltip>
                      </div>

                    </>

                  )}

                </Card>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={18}>
                <Checkbox onChange={(e) => setAllowDuplicate(e.target.checked)}>Allow duplicate recipients</Checkbox>
              </Col>
              <Col className='ml-md-0 text-md-right' xs={24} sm={24} md={6}
                   style={{marginTop: '0px', ...(window.innerWidth < 767 && {marginTop: '20px'})}}>
                <RecipientDropdown/>
              </Col>
            </Row>
            <br/><br/>
            <h4 className='mb-0'>Scheduling</h4>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12} lg={12} className='pr-0'>
                <Form.Item
                  label="Date-Time"
                  name="campaignSchedule">
                  <DatePicker showTime onChange={e => {
                    if (e) {
                      setRequiredTimezone(true);
                      // Use moment-timezone to get the formatted time zone
                      const timezoneOffset = moment.tz.guess(true); // Use true to get the long format

                      setSchedulesTZ(timezoneOffset);
                    } else {
                      setRequiredTimezone(false);
                    }
                  }}
                              format="YYYY-MM-DD HH:mm:ss"
                              className='w-100'/>

                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  label="Timezone"
                  rules={[
                    {
                      required: requiredTimezone,
                      message: 'Please select Timezone!',
                    },
                  ]}
                >

                  <Select
                    showSearch
                    style={{width: 200}}
                    value={scheduledTZ}
                    onChange={(e) => setSchedulesTZ(e)}
                    placeholder="Timezone"
                    optionFilterProp="children"
                    // onChange={onChangeTimeZone}
                    filterOption={(input, option) =>
                      option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    className='w-100'
                  >
                    {TimeZone.map((e) => (
                      <Select.Option key={e.name} value={e.value} title={`${e.value}`}>
                        {e.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <br/>
            <Row gutter={16}>
              {(selectedContacts.length > 0 || selectedContactGroups.length > 0 || selectedRecipientFile.length > 0) ? (
                <Col xs={24} sm={24} md={24} lg={24}
                     style={{marginBottom: '0px', ...(window.innerWidth < 576 && {marginBottom: '20px'})}}>
                  <Collapse
                    // defaultActiveKey={['1']}
                    expandIcon={expandIcon}
                    className="site-collapse-custom-collapse"
                  >
                    <Panel header="Filtering" key="1" className="site-collapse-custom-panel">
                      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div>
                          {filteringData.length > 0 && (
                            <Card
                              style={{width: '100%', marginBottom: 16}}
                              hoverable
                            >
                              <Card.Meta
                                description={filteringData.map((data, index) => (
                                  // eslint-disable-next-line react/no-array-index-key
                                  <React.Fragment key={index}>
                                    {index !== 0 && ', '}
                                    <Popover content={<div><b>{data.field} {data.operator} {data.value}</b></div>}>
                                    <span>
          {data.field}
                                      <Button
                                        type="text"
                                        icon={<CloseOutlined/>}
                                        onClick={() => {
                                          const updatedCardsData = [...filteringData];
                                          updatedCardsData.splice(index, 1);
                                          setFilteringData(updatedCardsData);
                                          setIsFiltering(false);
                                        }}
                                        style={{marginLeft: 4}}
                                      />
        </span>
                                    </Popover>
                                  </React.Fragment>
                                ))}
                              />
                            </Card>
                          )}
                        </div>
                        <Button type="default" onClick={showModal} style={{marginBottom: 16}}>
                          <PlusOutlined/> Filter
                        </Button>
                      </div>
                    </Panel>
                  </Collapse>
                </Col>
              ) : null}
            </Row>
            <Row gutter={16}>
              {/* Recipient Input Box */}


              {/* Add Contact and Add Group Buttons */}
              <Col xs={24} sm={24} md={24} lg={8}>
                <Col xs={24} sm={24} md={24} lg={24}>
                  {/* <CampaignContactPicker */}
                  {/*  visible={showContactPicker} */}
                  {/*  onClose={onCloseContactPicker} */}
                  {/*  contacts={contacts} */}
                  {/*  total={contactsTotal} */}
                  {/*  loading={contactLoading} */}
                  {/*  onPaginate={onContactPaginate} */}
                  {/*  selectedContacts={selectedContacts} */}
                  {/*  onOk={onSelectContacts}/> */}

                  <CampaignContactPicker
                    originator={originator}
                    visible={showContactPicker}
                    onClose={onCloseContactPicker}
                    contacts={contacts}
                    total={contactsTotal}
                    loading={contactLoading}
                    onPaginate={onContactPaginate}
                    selectedContacts={selectedContacts}
                    onOk={onSelectContacts}/>

                  <CampaignGroupPicker visible={showContactGroupPicker} onClose={onCloseContactGroupPicker}
                                       contactGroups={contactGroups} loading={contactGroupLoading}
                                       onOk={onSelectContactGroups}
                                       selectedContactGroups={selectedContactGroups}/>

                </Col>
              </Col>
            </Row>
          </Col>
          <Col lg={1}/>
          <Col xs={16} sm={10} lg={5} md={5} style={chatContainerStyle}>
            <ChatPreviewPage headerText={selectedTemplate.header_text} bodyText={selectedTemplate.body_text}
                             footerText={selectedTemplate.footer_text}
                             selectedMediaType={selectedTemplate.header_format}
                             buttonType={selectedTemplate.buttons_type}
                             actionType={selectedTemplate.buttons_type}
                             optOutButton={false}
                             actionButtonsQuickReply={buttonTextValues}
                             actionButtonsPhoneNumber={buttonPhoneTextValues}
                             actionButtonsUrl={buttonUrlTextValues}
                             buttonCopyCodeTextValues={buttonCopyCodeTextValues}
                             isCarousel={!!messageFormModel?.content?.carousel} inputDirection={inputDirection}/>
          </Col>
          <Col lg={1} xl={1}/>
        </Row>
        <Row gutter={16}>
          <Col lg={1}/>
          <Col xs={24} sm={24} md={11} lg={11} >
            <div style={{marginTop: '4px', ...(window.innerWidth >= 400 && {marginTop: '100px'})} as any} >
              <div className='font-size-sm' >{fileFormatInstructions}</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={11} xl={11}>
            <div className="text-right"
                 style={{marginTop: '4px', ...(window.innerWidth >= 400 && {marginTop: '100px'})} as any}>
              <Button htmlType="button" onClick={onClickPrev} disabled={uploadingFile}>
                Prev
              </Button>
              <Button className="ml-2" disabled={uploadingFile}
                      onClick={onClickSubmitForm} type="primary">
                Next
              </Button>
            </div>
          </Col>
          <Col lg={1}/>
        </Row>


        <Modal
          title="Add Details"
          visible={isModalVisible}
          // onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            form={filteringForm}
            onFinish={values => {
              handleOk(values); // Pass form values to handleOk
            }}
          >
            <Form.Item labelAlign="left" labelCol={{span: 4}} label="Field" name="field" rules={[{required: true}]}>
              <Select placeholder="Select Field">
                {PERSONALIZATION_FIELDS.map((field) => (
                  <Select.Option key={field.value} value={field.value}>
                    {field.text} {filterFieldMappingValue[field.value] && `(${filterFieldMappingValue[field.value]})`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item labelAlign="left" labelCol={{span: 4}} label="Operator" name="operator"
                       rules={[{required: true}]}>
              <Select placeholder="Select Operator">
                {OPERATORS.map((operator) => (
                  <Select.Option key={operator.value} value={operator.value}>
                    {operator.text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item labelAlign="left" labelCol={{span: 4}} label="Value" name="value" rules={[{required: true}]}>
              <Input/>
            </Form.Item>
            <Form.Item className="text-right">
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Add Recipients"
          visible={showRecipientsAdder}
          onCancel={handleCancelRecipientsModal}
          footer={null}
        >
          <Form
            form={scheduleForm}
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            onFinish={onClickAddRecipient}
          >
            <Form.Item
              // label="Phone"
              name={['recipients', 'phone']}
              labelAlign="left" labelCol={{span: 8}}
              extra="Maximum number of recipients  is 1000"
              label={
                <span>
                          Phone Numbers
                          <InfoCircleOutlined
                            style={{fontSize: '16px', marginLeft: '8px', cursor: 'pointer'}}
                            onClick={() => {
                              Modal.info({
                                title: "Phone with Country Code",
                                content: (
                                  <div>
                                    Here you can add your phone numbers with country codes. You can enter multiple
                                    numbers in a single click by separating them with commas or adding each number on a
                                    new line.
                                  </div>
                                ),
                              });
                            }}
                          />
                      </span>}
              rules={[
                {
                  validator: (_, value) => {
                    if (!validatePhoneNumbers(value)) {
                      return Promise.reject(new Error('Maximum 1000 numbers allowed.'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <TextArea size="large" style={{height: '160px'}} value={phoneNumbers}
                        onChange={handlePhoneNumbersChange}/>
            </Form.Item>
            <Form.Item className="text-right">
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Form>
      <CampaignFileMapping headerlist={headerList} onClickSubmit={onClickFileMappingSave}
                           onClickCloseMappingModal={onClickCloseMappingModal} visible={isFileMapping}/>
    </>);
};

CampaignScheduleTab.defaultProps = {
  whatsappCampaign: undefined,
  messageFormModel: undefined,
  selectedTemplate: undefined,
  onClickPrev: undefined,
  schedule: undefined,
  errorMessage: undefined,
  errorMessageValidation: undefined,
  buttonTextValues: undefined,
  buttonPhoneTextValues: undefined,
  buttonUrlTextValues: undefined,
  buttonCopyCodeTextValues: undefined,
  inputDirection: undefined,
  originator: undefined,
};

export default CampaignScheduleTab;
