interface Translations {
  [key: string]: {
    stopPromotions: string;
    notInterested: string;
    copyOfferCode: string;
  };
}

const translatedSentence: Translations = {
  af: {
    stopPromotions: 'Stop promosies',
    notInterested: 'Nie geïnteresseerd nie? Tik Stop promosies',
    copyOfferCode: 'Kopieer aanbodkode',
  },
  sq: {
    stopPromotions: 'Ndalo promovimet',
    notInterested: 'Nuk jeni i interesuar? Shtyp Ndalo promovimet',
    copyOfferCode: 'Kopjo kodin e ofertës',
  },
  ar: {
    stopPromotions: 'إيقاف العروض',
    notInterested: 'غير مهتم؟ اضغط على إيقاف العروض',
    copyOfferCode: 'انسخ رمز العرض',
  },
  az: {
    stopPromotions: 'Promosyonları dayandır',
    notInterested: 'Maraqlanmırsınız? Promosyonları dayandırın',
    copyOfferCode: 'Təklif kodunu kopyalayın',
  },
  bn: {
    stopPromotions: 'প্রচার বন্ধ করুন',
    notInterested: 'আগ্রহী নন? প্রচার বন্ধ করতে ট্যাপ করুন',
    copyOfferCode: 'অফার কোড কপি করুন',
  },
  bg: {
    stopPromotions: 'Спрете промоциите',
    notInterested: 'Не се интересувате? Докоснете Спрете промоциите',
    copyOfferCode: 'Копирайте кода за оферта',
  },
  ca: {
    stopPromotions: 'Atura promocions',
    notInterested: "No t'interessa? Toca Atura promocions",
    copyOfferCode: 'Copia el codi de l’oferta',
  },
  zh_CN: {
    stopPromotions: '停止促销',
    notInterested: '不感兴趣？点击停止促销',
    copyOfferCode: '复制优惠代码',
  },
  zh_HK: {
    stopPromotions: '停止推廣',
    notInterested: '不感興趣？點擊停止推廣',
    copyOfferCode: '複製優惠代碼',
  },
  zh_TW: {
    stopPromotions: '停止推廣',
    notInterested: '不感興趣？點擊停止推廣',
    copyOfferCode: '複製優惠代碼',
  },
  hr: {
    stopPromotions: 'Zaustavi promocije',
    notInterested: 'Niste zainteresirani? Dodirnite Zaustavi promocije',
    copyOfferCode: 'Kopiraj kod ponude',
  },
  cs: {
    stopPromotions: 'Zastavit propagaci',
    notInterested: 'Nemáte zájem? Klepněte na Zastavit propagaci',
    copyOfferCode: 'Zkopírujte kód nabídky',
  },
  da: {
    stopPromotions: 'Stop kampagner',
    notInterested: 'Ikke interesseret? Tryk på Stop kampagner',
    copyOfferCode: 'Kopiér tilbudskode',
  },
  nl: {
    stopPromotions: 'Stop promoties',
    notInterested: 'Niet geïnteresseerd? Tik op Stop promoties',
    copyOfferCode: 'Kopieer aanbiedingscode',
  },
  en: {
    stopPromotions: 'Stop promotions',
    notInterested: 'Not interested? Tap Stop promotions',
    copyOfferCode: 'Copy offer code',
  },
  en_GB: {
    stopPromotions: 'Stop promotions',
    notInterested: 'Not interested? Tap Stop promotions',
    copyOfferCode: 'Copy offer code',
  },
  en_US: {
    stopPromotions: 'Stop promotions',
    notInterested: 'Not interested? Tap Stop promotions',
    copyOfferCode: 'Copy offer code',
  },
  et: {
    stopPromotions: 'Peata kampaaniad',
    notInterested: 'Pole huvitatud? Puudutage Peata kampaaniad',
    copyOfferCode: 'Kopeeri pakkumiskood',
  },
  fil: {
    stopPromotions: 'Itigil ang promosyon',
    notInterested: 'Hindi interesado? I-tap ang Itigil ang promosyon',
    copyOfferCode: 'Kopyahin ang code ng alok',
  },
  fi: {
    stopPromotions: 'Lopeta kampanjat',
    notInterested: 'Etkö ole kiinnostunut? Napauta Lopeta kampanjat',
    copyOfferCode: 'Kopioi tarjouskoodi',
  },
  fr: {
    stopPromotions: 'Arrêter les promotions',
    notInterested: 'Pas intéressé ? Appuyez sur Arrêter les promotions',
    copyOfferCode: "Copier le code de l'offre",
  },
  ka: {
    stopPromotions: 'შეაჩერე პრომოციები',
    notInterested: 'არ ხართ დაინტერესებული? დააჭირეთ შეაჩერე პრომოციები',
    copyOfferCode: 'დააკოპირეთ შეთავაზების კოდი',
  },
  de: {
    stopPromotions: 'Aktionen stoppen',
    notInterested: 'Kein Interesse? Tippen Sie auf Aktionen stoppen',
    copyOfferCode: 'Angebotscode kopieren',
  },
  el: {
    stopPromotions: 'Διακοπή προωθήσεων',
    notInterested: 'Δεν ενδιαφέρεστε; Πατήστε Διακοπή προωθήσεων',
    copyOfferCode: 'Αντιγραφή κωδικού προσφοράς',
  },
  gu: {
    stopPromotions: 'પ્રમોશન બંધ કરો',
    notInterested: 'રસ નથી? પ્રોમોશન બંધ કરવા ટેપ કરો',
    copyOfferCode: 'ઓફર કોડ કોપી કરો',
  },
  ha: {
    stopPromotions: 'Tsaya da tallace-tallace',
    notInterested: 'Ba ku sha’awar ba? Danna Tsaya da tallace-tallace',
    copyOfferCode: 'Kwafi lambar tayin',
  },
  he: {
    stopPromotions: 'עצור מבצעים',
    notInterested: 'לא מעוניין? הקש על עצור מבצעים',
    copyOfferCode: 'העתק קוד מבצע',
  },
  hi: {
    stopPromotions: 'प्रचार बंद करें',
    notInterested: 'रुचि नहीं है? प्रचार बंद करने के लिए टैप करें',
    copyOfferCode: 'ऑफर कोड कॉपी करें',
  },
  hu: {
    stopPromotions: 'Promóciók leállítása',
    notInterested: 'Nem érdekli? Érintse meg a Promóciók leállítása',
    copyOfferCode: 'Ajánlatkód másolása',
  },
  id: {
    stopPromotions: 'Hentikan promosi',
    notInterested: 'Tidak tertarik? Ketuk Hentikan promosi',
    copyOfferCode: 'Salin kode penawaran',
  },
  ga: {
    stopPromotions: 'Stop le cur chun cinn',
    notInterested: 'Nach bhfuil suim agat? Beartaigh Stop le cur chun cinn',
    copyOfferCode: 'Cóipeáil cód tairisceana',
  },
  it: {
    stopPromotions: 'Interrompi promozioni',
    notInterested: 'Non interessato? Tocca Interrompi promozioni',
    copyOfferCode: "Copia il codice dell'offerta",
  },
  ja: {
    stopPromotions: 'プロモーションを停止',
    notInterested: '興味がありませんか？「プロモーションを停止」をタップ',
    copyOfferCode: 'オファーコードをコピー',
  },
  kn: {
    stopPromotions: 'ಪ್ರಚಾರ ನಿಲ್ಲಿಸಿ',
    notInterested: 'ಆಸಕ್ತಿ ಇಲ್ಲವೇ? ಪ್ರಚಾರ ನಿಲ್ಲಿಸಲು ಟ್ಯಾಪ್ ಮಾಡಿ',
    copyOfferCode: 'ಆಫರ್ ಕೋಡ್ ನಕಲಿಸಿ',
  },
  kk: {
    stopPromotions: 'Акцияларды тоқтату',
    notInterested: 'Қызықтырмай ма? Акцияларды тоқтатуды басыңыз',
    copyOfferCode: 'Ұсыныс кодын көшіру',
  },
  rw_RW: {
    stopPromotions: 'Hagarika promosiyo',
    notInterested: 'Ntabwo wifuza? Kanda Hagarika promosiyo',
    copyOfferCode: 'Koporora kode y’itangwa',
  },
  ko: {
    stopPromotions: '프로모션 중단',
    notInterested: '관심 없으신가요? 프로모션 중단을 탭하세요',
    copyOfferCode: '제공 코드 복사',
  },
  ky_KG: {
    stopPromotions: 'Акцияларды токтотуу',
    notInterested: 'Кызыкдар эмессизби? Акцияларды токтотууну басыңыз',
    copyOfferCode: 'Сунуш кодун көчүрүү',
  },
  lo: {
    stopPromotions: 'ຢຸດການສົ່ງເສີມ',
    notInterested: 'ບໍ່ສົນໃຈຫຼື? ກົດຢຸດການສົ່ງເສີມ',
    copyOfferCode: 'ຄອບລະຫັດສົດສະເຫີຍ',
  },
  lv: {
    stopPromotions: 'Pārtraukt akcijas',
    notInterested: 'Nav interesē? Noklikšķiniet uz Pārtraukt akcijas',
    copyOfferCode: 'Kopēt piedāvājuma kodu',
  },
  lt: {
    stopPromotions: 'Sustabdyti akcijas',
    notInterested: 'Nesidomite? Paspauskite Sustabdyti akcijas',
    copyOfferCode: 'Nukopijuoti pasiūlymo kodą',
  },
  mk: {
    stopPromotions: 'Запирање на промоции',
    notInterested: 'Не сте заинтересирани? Допрете Запирање на промоции',
    copyOfferCode: 'Копирај код за понуда',
  },
  ms: {
    stopPromotions: 'Berhenti promosi',
    notInterested: 'Tidak berminat? Ketik Berhenti promosi',
    copyOfferCode: 'Salin kod tawaran',
  },
  ml: {
    stopPromotions: 'പ്രചാരണം നിർത്തുക',
    notInterested: 'ആഗ്രഹമില്ലേ? പ്രചാരണം നിർത്താൻ ടാപ്പ് ചെയ്യൂ',
    copyOfferCode: 'ഓഫർ കോഡ് പകർത്തുക',
  },
  mr: {
    stopPromotions: 'प्रचार थांबवा',
    notInterested: 'रुचि नाही का? प्रचार थांबवण्यासाठी टॅप करा',
    copyOfferCode: 'ऑफर कोड कॉपी करा',
  },
  nb: {
    stopPromotions: 'Stopp kampanjer',
    notInterested: 'Ikke interessert? Trykk på Stopp kampanjer',
    copyOfferCode: 'Kopier tilbudskode',
  },
  fa: {
    stopPromotions: 'متوقف کردن تبلیغات',
    notInterested: 'مشتاق نیستید؟ برای متوقف کردن تبلیغات ضربه بزنید',
    copyOfferCode: 'کپی کد پیشنهاد',
  },
  pl: {
    stopPromotions: 'Zatrzymaj promocje',
    notInterested: 'Nie zainteresowany? Naciśnij Zatrzymaj promocje',
    copyOfferCode: 'Kopiuj kod oferty',
  },
  pt_BR: {
    stopPromotions: 'Parar promoções',
    notInterested: 'Não interessado? Toque em Parar promoções',
    copyOfferCode: 'Copiar código da oferta',
  },
  pt_PT: {
    stopPromotions: 'Parar promoções',
    notInterested: 'Não interessado? Toque em Parar promoções',
    copyOfferCode: 'Copiar código da oferta',
  },
  pa: {
    stopPromotions: 'ਪ੍ਰੋਮੋਸ਼ਨ ਬੰਦ ਕਰੋ',
    notInterested: 'ਰੁਚੀ ਨਹੀਂ? ਪ੍ਰੋਮੋਸ਼ਨ ਬੰਦ ਕਰਨ ਲਈ ਟੈਪ ਕਰੋ',
    copyOfferCode: 'ਪੇਸ਼ਕਸ਼ ਕੋਡ ਕਾਪੀ ਕਰੋ',
  },
  ro: {
    stopPromotions: 'Oprește promoțiile',
    notInterested: 'Nu ești interesat? Apasă Oprește promoțiile',
    copyOfferCode: 'Copiază codul ofertei',
  },
  ru: {
    stopPromotions: 'Остановить акции',
    notInterested: 'Не интересует? Нажмите Остановить акции',
    copyOfferCode: 'Скопировать код предложения',
  },
  sr: {
    stopPromotions: 'Zaustavite promocije',
    notInterested: 'Niste zainteresovani? Dodirnite Zaustavite promocije',
    copyOfferCode: 'Kopirajte kod ponude',
  },
  sk: {
    stopPromotions: 'Zastaviť propagácie',
    notInterested: 'Nemáte záujem? Klepnite na Zastaviť propagácie',
    copyOfferCode: 'Kopírujte kód ponuky',
  },
  sl: {
    stopPromotions: 'Ustavite promocije',
    notInterested: 'Niste zainteresirani? Dotaknite se Ustavite promocije',
    copyOfferCode: 'Kopirajte kodo ponudbe',
  },
  es: {
    stopPromotions: 'Detener promociones',
    notInterested: '¿No estás interesado? Toca Detener promociones',
    copyOfferCode: 'Copiar código de oferta',
  },
  es_AR: {
    stopPromotions: 'Detener promociones',
    notInterested: '¿No estás interesado? Toca Detener promociones',
    copyOfferCode: 'Copiar código de oferta',
  },
  es_ES: {
    stopPromotions: 'Detener promociones',
    notInterested: '¿No estás interesado? Toca Detener promociones',
    copyOfferCode: 'Copiar código de oferta',
  },
  es_MX: {
    stopPromotions: 'Detener promociones',
    notInterested: '¿No estás interesado? Toca Detener promociones',
    copyOfferCode: 'Copiar código de oferta',
  },
  sw: {
    stopPromotions: 'Acha matangazo',
    notInterested: 'Huna nia? Gusa Acha matangazo',
    copyOfferCode: 'Nakili msimbo wa ofa',
  },
  sv: {
    stopPromotions: 'Stoppa kampanjer',
    notInterested: 'Inte intresserad? Tryck på Stoppa kampanjer',
    copyOfferCode: 'Kopiera erbjudandekod',
  },
  ta: {
    stopPromotions: 'ப்ரமோஷன்களை நிறுத்துங்கள்',
    notInterested: 'தற்பொருள் இல்லை? ப்ரமோஷன்களை நிறுத்த தட்டுங்கள்',
    copyOfferCode: 'ஓப்பர் குறியீட்டை நகலெடுக்கவும்',
  },
  te: {
    stopPromotions: 'ప్రచారాలను ఆపండి',
    notInterested: 'ఆసక్తి లేదు? ప్రచారాలను ఆపడానికి ట్యాప్ చేయండి',
    copyOfferCode: 'ఆఫర్ కోడ్ నకలు చేయండి',
  },
  th: {
    stopPromotions: 'หยุดโปรโมชั่น',
    notInterested: 'ไม่สนใจ? แตะที่ หยุดโปรโมชั่น',
    copyOfferCode: 'คัดลอกโค้ดข้อเสนอ',
  },
  tr: {
    stopPromotions: 'Promosyonları durdur',
    notInterested: 'İlgilenmiyor musunuz? Promosyonları durdurun',
    copyOfferCode: 'Teklif kodunu kopyala',
  },
  uk: {
    stopPromotions: 'Зупинити акції',
    notInterested: 'Не цікаво? Натисніть Зупинити акції',
    copyOfferCode: 'Скопіювати код пропозиції',
  },
  ur: {
    stopPromotions: 'تشہیر بند کریں',
    notInterested: 'دلچسپی نہیں؟ تشہیر بند کرنے کے لیے دبائیں',
    copyOfferCode: 'آفر کوڈ کاپی کریں',
  },
  uz: {
    stopPromotions: 'Promosiyalarni to‘xtatish',
    notInterested: 'Qiziqmaysizmi? Promosiyalarni to‘xtatish tugmasini bosing',
    copyOfferCode: 'Taklif kodini nusxalash',
  },
  vi: {
    stopPromotions: 'Dừng khuyến mãi',
    notInterested: 'Không quan tâm? Nhấn Dừng khuyến mãi',
    copyOfferCode: 'Sao chép mã ưu đãi',
  },
  zu: {
    stopPromotions: 'Misa ukukhushulwa',
    notInterested: 'Awunandaba? Thepha Misa ukukhushulwa',
    copyOfferCode: 'Kopisha ikhodi yesiphakamiso',
  },
};
export default translatedSentence;
