import { WhatsappChatExportResponse } from "models/responses/WhatsappChatExportResponse";
import {
  WhatsappMetaOnboardRequest,
  WhatsappTemplateCreateRequest,
  WhatsappTemplateDeleteRequest,
  WhatsappTemplateUpdateRequest,
  WhatsappAnalyticsStatusRequest, InterveneInboxRequest,
  WhatsappAnalyticsUserInitiatedStatusRequest
} from "../../models/requests";

import {
  WhatsappMetaBusinessProfile,
  WhatsappMetaChatList,
  WhatsappMetaContactList,
  WhatsappMetaDisplayNameEdit,
  WhatsappMetaWabaList,
} from "../../models";

import {
  FileResumableUploadResponse,
  MediaUploadResponse,
  WhatsappMetaTemplateCreateResponse,
  WhatsappTemplateDeleteResponse,
  WhatsappTemplateListResponse,
  WhatsappTemplateUpdateResponse,
  WhatsappRecipientAnalyticsResponse
} from "../../models/responses";
import WhatsappMetaApi from "./index";

const listWhatsappMetaRegisteredNumbers = async () =>
  WhatsappMetaApi.get<WhatsappMetaContactList>(
    "meta-onboarding/onboarded-numbers"
  );
const getWhatsappMetaRegisteredNumbers = async (number: string) =>
  WhatsappMetaApi.get<WhatsappMetaContactList>(`meta-onboarding/${number}`);
const whatsappMetaOnboarding = async (data: WhatsappMetaOnboardRequest) =>
  WhatsappMetaApi.post<string, WhatsappMetaOnboardRequest>(
    "meta-onboarding",
    data
  );
const createWhatsappMetaTemplate = async (
  data: WhatsappTemplateCreateRequest
) =>
  WhatsappMetaApi.post<
    WhatsappMetaTemplateCreateResponse,
    WhatsappTemplateCreateRequest
  >("meta-template/create", data);
const updateWhatsappMetaTemplate = async (
  data: WhatsappTemplateUpdateRequest,
  templateId: number
) =>
  WhatsappMetaApi.put<
    WhatsappTemplateUpdateResponse,
    WhatsappTemplateUpdateRequest
  >(`meta-template/update/${templateId}`, data);
const listWhatsappMetaTemplate = async (wabaId: string) =>
  WhatsappMetaApi.get<WhatsappTemplateListResponse>(
    `meta-template?waba_id=${wabaId}`
  );

const listWhatsappMetaMarketingTemplate = async (number: string) =>
  WhatsappMetaApi.get<WhatsappTemplateListResponse>(
    `meta-template/marketing_template/${number}`
  );
const listWhatsappMetaAuthenticationTemplate = async (number: string) =>
  WhatsappMetaApi.get<WhatsappTemplateListResponse>(
    `meta-template/authentication_template/${number}`
  );
const deleteWhatsappMetaTemplate = async (
  data: WhatsappTemplateDeleteRequest
) =>
  WhatsappMetaApi.delete<WhatsappTemplateDeleteResponse>(
    `meta-template/delete/${data.id}`
  );

const uploadResumableFile = async (data: any) =>
  WhatsappMetaApi.post<FileResumableUploadResponse>(
    "meta-template/uploads",
    data,
    false,
    {
      "Content-Type": "multipart/form-data",
    }
  );

const listWhatsappMetaWabaId = async () =>
  WhatsappMetaApi.get<WhatsappMetaWabaList>("meta-numbers");

const getOriginator = async (originator?: string) =>
  WhatsappMetaApi.get<WhatsappMetaContactList>(
    `meta-onboarding/onboarded-numbers/${originator}`
  );

const uploadMediaFile = async (originator: any, data: any) =>
  WhatsappMetaApi.post<MediaUploadResponse>(
    `media/${originator}`,
    data,
    false,
    {
      "Content-Type": "multipart/form-data",
    }
  );

const getWhatsappMetaBusinessProfile = async (number: string) =>
  WhatsappMetaApi.get<WhatsappMetaBusinessProfile>(`meta-profile/${number}`);

const updateWhatsappMetaBusinessProfile = async (
  ContactNumber: string | undefined,
  data: WhatsappMetaBusinessProfile
) =>
  WhatsappMetaApi.post<
    WhatsappMetaBusinessProfile,
    WhatsappMetaBusinessProfile
  >(`meta-profile/update/${ContactNumber}`, data);

const checkNumberIntervened = async (registeredNumber: string, customerNumber: string) => WhatsappMetaApi.get<boolean>(`meta-inbox/intervene-inbox/${registeredNumber}/${customerNumber}`);

const InterveneChat = async (data: InterveneInboxRequest) => WhatsappMetaApi.post<string>('meta-inbox/intervene-inbox', data);
const resumeChatbotInChat = async (registeredNumber: string, customerNumber: string) => WhatsappMetaApi.delete(`meta-inbox/end-intervene-inbox/${registeredNumber}/${customerNumber}`);
const listWhatsappChatsByPhoneNumber = async (number:string, pageNumber: number, pageSize:number, searchQuery:string) => WhatsappMetaApi.get<WhatsappMetaChatList>('meta-inbox', {phone_number: number, page: pageNumber, page_size: pageSize, search_key: searchQuery});

const listWhatsappChatMessagesByPhoneNumber = async (
  brandNumber: string,
  customerNumber: string,
  pageNumber: number,
  abortSignal?: AbortSignal
) =>
  WhatsappMetaApi.getWithSignal<any>(
    `meta-inbox/${customerNumber}`,
    { brand_number: brandNumber, page: pageNumber },
    false,
    {},
    abortSignal
  );
const updateWhatsappMetaDisplayName = async (
  ContactNumber: string | undefined,
  data: WhatsappMetaDisplayNameEdit
) =>
  WhatsappMetaApi.post<any, any>(
    `meta-profile/display_name_update/${ContactNumber}`,
    data
  );

const getWhatsappChatExport = (brandNumber: string, customerNumber: string ) => WhatsappMetaApi.get<WhatsappChatExportResponse>(`meta-inbox/conversation_export/${customerNumber}/${brandNumber}`);

const fetchWhatsappMediaFile = async (mediaId: string, onDownloadProgress: (progressEvent: ProgressEvent) => void) => WhatsappMetaApi.getBlobWithProgress(`media/${mediaId}`, onDownloadProgress);


const getWhatsappTemplateMessage = async (templateId: string, temLanguage: string) => WhatsappMetaApi.get("meta-template/get-template", {language: temLanguage, template_id: templateId});
const getWhastappAnalyticsRecipientsService = async (data: WhatsappAnalyticsStatusRequest) => WhatsappMetaApi.get<WhatsappRecipientAnalyticsResponse>("report/campaign-recipients", data) ;
const getWhastappAnalyticsRecipientsServiceUserInitiated = async (data: WhatsappAnalyticsUserInitiatedStatusRequest) => WhatsappMetaApi.get<WhatsappRecipientAnalyticsResponse>("report/campaign-recipients-user-initiated", data) ;
export {
  createWhatsappMetaTemplate,
  deleteWhatsappMetaTemplate,
  getOriginator, getWhatsappChatExport, getWhatsappMetaBusinessProfile,
  getWhatsappMetaRegisteredNumbers,
  listWhatsappChatMessagesByPhoneNumber,
  listWhatsappChatsByPhoneNumber,
  listWhatsappMetaAuthenticationTemplate,
  listWhatsappMetaMarketingTemplate,
  listWhatsappMetaRegisteredNumbers,
  listWhatsappMetaTemplate,
  listWhatsappMetaWabaId,
  updateWhatsappMetaBusinessProfile,
  updateWhatsappMetaDisplayName,
  updateWhatsappMetaTemplate,
  uploadMediaFile,
  uploadResumableFile,
  whatsappMetaOnboarding,
  fetchWhatsappMediaFile,
  getWhatsappTemplateMessage,
  getWhastappAnalyticsRecipientsService,
  getWhastappAnalyticsRecipientsServiceUserInitiated,
  checkNumberIntervened,
  InterveneChat,
  resumeChatbotInChat
};

