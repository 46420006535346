import React, { useEffect } from 'react';
import {
  WhatsappCampaignMessageFormModel,
  WhatsappCampaignScheduleFormModel
} from 'models/forms';
import { WhatsappCampaignSummary} from "models";

import { ColumnsType } from 'antd/es/table';
import { Button, Col, Row, Table } from 'antd';
import {FieldTimeOutlined, FilterOutlined, InfoCircleOutlined, OrderedListOutlined} from "@ant-design/icons";
import ChatPreviewPage from "../../WhatsappTemplate/WhatsappTemplatePreview";
// import ChatPreviewPage from "../../../sms/campaign/CampaignSMSPreview";

export interface WhatsappCampaignVerifyTabProps {
  campaignVerificationList?: WhatsappCampaignSummary[];
  messageFormModel?: WhatsappCampaignMessageFormModel;
  scheduleFormModel?: WhatsappCampaignScheduleFormModel;
  onClickPrev?: () => void;
  onSubmitForm?: () => void;
  loading?: boolean;
  selectedTemplate?: any;
  buttonTextValues?: any;
  buttonPhoneTextValues?: any;
  buttonUrlTextValues?: any;
  buttonCopyCodeTextValues?: any;
  inputDirection?: any;
};


const WhatsappCampaignVerifyTab = (props: WhatsappCampaignVerifyTabProps) => {
  const { campaignVerificationList, messageFormModel, scheduleFormModel, onClickPrev, onSubmitForm, loading, selectedTemplate,     buttonTextValues,
    buttonPhoneTextValues,
    buttonUrlTextValues,
    buttonCopyCodeTextValues, inputDirection} = props;
  // Note section content
  const fileFormatInstructions = (
    <div className="mb-2">
      {/* <h5>Import File Format Instructions:</h5> */}
      <p><b className="font-weight-bolder"><InfoCircleOutlined/></b> SMS count will be varying based on personalised
        variable length.</p>
      <p><b className="font-weight-bolder"><InfoCircleOutlined/></b> Recipients count will be varying based on filtering
        applied.</p>
    </div>
  );


  const tableColumns: ColumnsType<WhatsappCampaignSummary> = [
    {
      title: 'Country',
      dataIndex: 'country',
    },
    {
      title: 'Total Recipients',
      dataIndex: 'totalRecipients',
    },
  ];

  const filteringValues = scheduleFormModel?.filtering || [];

  const tableColumnFilters: ColumnsType<WhatsappCampaignSummary> = [
    {
      title: 'Field',
      dataIndex: 'field',
    },
    {
      title: 'Value',
      dataIndex: 'value',
    }
  ];


  const mappedFilteringValues = filteringValues.map((filter: any, index: any) => ({
    key: index, // Adding a unique key for each row
    field: `${filter.field} (${filter.operator})`,
    value: filter.value,
    operator: filter.operator,
  }));
  useEffect(() => {
  }, [campaignVerificationList, messageFormModel, scheduleFormModel]);

  const chatContainerStyle: React.CSSProperties = {
    margin: '20px auto',
    padding: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    background: `url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png')`, // Replace with your image URL
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
  };


  return (
    <>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={1}/>
        <style>
          {`
            .custom-table .ant-table-thead > tr > th {
              background-color: #f0f0f0; /* Light gray color */
            }
        `}
        </style>
        <Col xs={24} sm={24} md={24} lg={15} xl={15}>
          <h5><OrderedListOutlined/><b className='ml-2'>Recipients Details</b></h5>
          <Col xs={24} sm={24} md={24} className='pl-0'>
            <div className="table-responsive">
              <Table<WhatsappCampaignSummary> columns={tableColumns} dataSource={campaignVerificationList}
                                           pagination={{pageSize: 4, hideOnSinglePage: true}}
                                           className="custom-table"/>
            </div>
          </Col>
          <br/><br/>
          {scheduleFormModel?.filtering.length > 0 &&
            <>
              <h5><FilterOutlined/><b className='ml-2'>Filters</b></h5>
              <Col xs={24} sm={24} md={24} className='pl-0'>
                <div className="table-responsive">
                  <Table<WhatsappCampaignSummary>
                    columns={tableColumnFilters}
                    dataSource={mappedFilteringValues}
                    pagination={{pageSize: 4, hideOnSinglePage: true}} className="custom-table"/>
                </div>
              </Col>
            </>
          }

        </Col>
        <Col lg={1}/>

        <Col xs={16} sm={10} lg={5} md={5} style={chatContainerStyle}>
          <ChatPreviewPage headerText={selectedTemplate.header_text} bodyText={selectedTemplate.body_text}
                           footerText={selectedTemplate.footer_text}
                           selectedMediaType={selectedTemplate.header_format}
                           buttonType={selectedTemplate.buttons_type}
                           actionType={selectedTemplate.buttons_type}
                           optOutButton={false}
                           actionButtonsQuickReply={buttonTextValues}
                           actionButtonsPhoneNumber={buttonPhoneTextValues}
                           actionButtonsUrl={buttonUrlTextValues}
                           buttonCopyCodeTextValues={buttonCopyCodeTextValues}
                           isCarousel={!!messageFormModel?.content?.carousel} inputDirection={inputDirection}/>
        </Col>
        <Col xs={24} sm={24} md={1}/>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={1}/>

        <Col xs={4} sm={8} lg={16} md={16}/>
        {scheduleFormModel?.scheduled_at && (
          <Col xs={16} sm={24} lg={5} md={5} style={{ paddingLeft: '2px', ...(window.innerWidth >= 600 && window.innerWidth <= 767 && { paddingLeft: '15px' }), ...(window.innerWidth >= 768 && window.innerWidth < 1441 && { paddingLeft:"18px" }), ...(window.innerWidth > 1441 && window.innerWidth < 1999 && { paddingLeft:"18px" }), ...(window.innerWidth > 2000 && window.innerWidth <= 2560 && { paddingLeft:"38px" }) } as any}>
            <b><FieldTimeOutlined className='font-size-lg'/> {scheduleFormModel?.scheduled_at}</b>
          </Col>
        )}
        <Col xs={24} sm={24} md={1}/>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={1}/>
        <Col xs={24} sm={24} md={11} lg={11}>
          <div style={{marginTop: '4px', ...(window.innerWidth >= 400 && {marginTop: '100px'})} as any}>
            <div className='font-size-sm'>{fileFormatInstructions}</div>
          </div>
        </Col>
        <Col lg={1}/>
        <Col xs={24} sm={24} md={10} lg={10}>
          <div className="text-right"
               style={{marginTop: '4px', ...(window.innerWidth >= 400 && {marginTop: '100px'})} as any}>
            <Button htmlType="submit" onClick={onClickPrev} disabled={loading}>
              Prev
            </Button>
            <Button type="primary" htmlType="submit" className="ml-2" loading={loading}
                    onClick={onSubmitForm}>
              Schedule & Send
            </Button>
          </div>
        </Col>
        <Col xs={24} sm={24} md={1}/>
      </Row>
    </>
  );
};

WhatsappCampaignVerifyTab.defaultProps = {
  campaignVerificationList: [],
  messageFormModel: undefined,
  scheduleFormModel: undefined,
  onClickPrev: undefined,
  onSubmitForm: undefined,
  loading: false,
  selectedTemplate: undefined,
  buttonTextValues: undefined,
  buttonPhoneTextValues: undefined,
  buttonUrlTextValues: undefined,
  buttonCopyCodeTextValues: undefined,
  inputDirection: undefined,
};

export default WhatsappCampaignVerifyTab;
