import React, {useEffect, useState} from 'react';
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  message as antdMessage, notification,
  Row,
  Select,
  Tooltip,
  Upload
} from 'antd';
import {CloudUploadOutlined, DeleteOutlined, InfoCircleOutlined} from "@ant-design/icons";
import moment from "moment/moment";
import {WhatsappCampaign, WhatsappCarousel, WhatsappTemplateDetails} from "../../../../models";
import {
  listWhatsappContactService,
  listWhatsappMetaMarketingTemplateService, uploadMediaFileService,
} from "../../../../services/dashboard/WhatsappService";
import ChatPreviewPage from "../../WhatsappTemplate/WhatsappTemplatePreview";
import {WhatsappCampaignMessageFormModel} from "../../../../models/forms";
import CampaignDynamicCarouselComponent from "../CampaignDynamicCarouselComponent";
import {showErrorNotification} from "../../../../utils/helper";


export interface WhatsappCampaignMessageTabProps {
  whatsappCampaign?: WhatsappCampaign;
  onClickNext: (WhatsappMessageModel: WhatsappCampaignMessageFormModel) => void;
  // onClickPrev?: () => void;
  originator: string;
  onSelectedTemplate?: any;
  onButtonTexts?: any;
  onInputDirection?: any;
}

const WhatsappCampaignMessageTab = (props: WhatsappCampaignMessageTabProps) => {
    const {whatsappCampaign, onClickNext, originator, onSelectedTemplate, onButtonTexts, onInputDirection} = props;
    const [messageForm] = Form.useForm();
    const [disableNext, setDisableNext] = useState<boolean>(false);
    const [fileList, setFileList] = useState<any>([]);
    const [templates, setTemplates] = useState<WhatsappTemplateDetails[]>();
    const [selectedTemplate, setSelectedTemplate] = useState({
      body_text: '', example_body_text: [], header_text: '', header_format: '',
      footer_text: '', example_header_text: [], media_url: '', buttons: {
        buttons_type: '',
        button_quick_reply: [{button_text_quick_reply: ''}],
        button_phone_number: [{button_text_phone_number: '', button_phone_number: ''}],
        button_url: [{button_text_url: '', button_url: '', example_url_values: ''}],
        button_copy_code: [{button_text_copy_code: ''}]
      },
      limited_time_offer: {has_expiration: false, limited_time_offer_text: ''},
      carousel: {
        components: [{
          media_url: '', body_text: '', example_body_text: [], buttons: {
            button_url: [{button_text_url: '', button_url: '', example_url_values: ''}]
          }
        }]
      }

    });

    const [bodyParameters, setBodyParameters] = useState<{ [key: string]: string }>({});
    const [loading, setLoading] = useState(false);
    const [buttonTextValues, setButtonTextValues] = useState<string[]>([]);
    const [buttonPhoneTextValues, setButtonPhoneTextValues] = useState<string[]>([]);
    const [buttonUrlTextValues, setButtonUrlTextValues] = useState<string[]>([]);
    const [buttonCopyCodeTextValues, setButtonCopyCodeTextValues] = useState<string[]>([]);
    const [buttonType, setButtonType] = useState('');
    const [actionType, setActionType] = useState('');
    const [personalizeMenuVisibility, setPersonalizeMenuVisibility] = useState({});
    const [parameterValue, setParameterValue] = useState('');
    const [showPersonalizationMenu, setShowPersonalizationMenu] = useState(false);
    const [isCarousel, setIsCarousel] = useState<boolean>(false);
    const [carouselComponent, setCarouselComponent] = useState<WhatsappCarousel>();
    const [originatorNummbers, setOriginatorNumber] = useState([{number: '', name: ''}]);
    const disabledDate = (current: any) => (current && current < moment().endOf('day'));
    const [inputDirection, setInputDirection] = useState('ltr');
    const [uploadingFile, setUploadingFile] = useState(false);
    const [selectedOriginator, setSelectedOriginator] = useState('');

    const PERSONALIZATION_FIELDS = [
      {text: 'First Name', value: '{first_name}'},
      {text: 'Last Name', value: '{last_name}'},
      {text: 'Email', value: '{email}'},
      {text: 'Number', value: '{number}'},
      {text: 'Var 1', value: '{var1}'},
      {text: 'Var 2', value: '{var2}'},
      {text: 'Var 3', value: '{var3}'},
      {text: 'Var 4', value: '{var4}'},
      {text: 'Var 5', value: '{var5}'},
      {text: 'Country', value: '{country}'},
      {text: 'Blacklist', value: '{blacklist}'}];

    const fetchPhoneNumber = async () => {
      setLoading(true);
      try {
        const fetchedPhoneNumbers: any = await listWhatsappContactService();
        setOriginatorNumber(fetchedPhoneNumbers);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // console.error('Error fetching templates:', error);
      }
    };

    const onCarouselSave = (carousel: WhatsappCarousel, isAllSaved: boolean) => {
      setDisableNext(isAllSaved);
      setCarouselComponent(carousel);
    };
    const handleParameterChange = (value: any) => {
      setParameterValue(value);
    };

    const handlePersonalizeChangeHeader = (value: any) => {
      const newValue = `{${value}}`;
      const currentValue = parameterValue || '';
      const updatedValuex = `${currentValue} ${newValue}`.trim();
      setParameterValue(updatedValuex);
      setShowPersonalizationMenu(false);
      messageForm.setFieldsValue({content: {media: {text_header_title: updatedValuex}}});
    };

    const personalizeMenu = (
      <div className="nav-profile nav-dropdown p-3" style={{height: 250, width: 220}}>
        <h5 className="mb-2">Personalize</h5>
        <Select
          showSearch
          style={{width: '100%'}}
          placeholder="Search"
          optionFilterProp="children"
          onChange={(value) => handlePersonalizeChangeHeader(value)}
          filterOption={(input: any, option: any) =>
            option?.key.toLowerCase().indexOf(input.toLowerCase()) >=
            0 ||
            option.title.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {PERSONALIZATION_FIELDS.map((e) => (
            <Select.Option key={e.value} value={e.value} title={e.text}>
              {e.text}
            </Select.Option>
          ))}
        </Select>
      </div>
    );


    const handleBodyParameterChange = (key: any, value: any) => {
      setBodyParameters({
        ...bodyParameters,
        [key]: value,
      });
    };

    const handlePersonalizeMenuVisibilityChange = (paramKey: any, visible: any) => {
      setPersonalizeMenuVisibility((prevVisibility) => ({...prevVisibility, [paramKey]: visible}));
    };

    const handlePersonalizeChange = (paramKey: any, value: any) => {
      const newValue = `{${value}}`;
      const updatedValue = bodyParameters[paramKey] ? `${bodyParameters[paramKey]} ${newValue}` : newValue;
      const updatedBodyParameters = {...bodyParameters, [paramKey]: updatedValue};
      setBodyParameters(updatedBodyParameters);
      handlePersonalizeMenuVisibilityChange(paramKey, false);

      // Set the form field value as well
      const currentValue =
        messageForm.getFieldValue(['content', 'body_parameter_values', paramKey]) || '';
      const updatedValuex = `${currentValue} ${newValue}`.trim();
      messageForm.setFieldsValue({
        content: {
          body_parameter_values: {...bodyParameters, [paramKey]: updatedValuex},
        },
      });
    };

    const createPersonalizeMenu = (paramKey: any) => (
      <div className="nav-profile nav-dropdown p-3" style={{height: 250, width: 220}}>
        <h5 className="mb-2">Personalize</h5>
        <Select
          showSearch
          style={{width: '100%'}}
          placeholder="Search"
          optionFilterProp="children"
          onChange={(value) => handlePersonalizeChange(paramKey, value)}
          filterOption={(input: any, option: any) =>
            option?.key.toLowerCase().indexOf(input.toLowerCase()) >=
            0 ||
            option.title.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {PERSONALIZATION_FIELDS.map((e) => (
            <Select.Option key={e.value} value={e.value} title={e.text}>
              {e.text}
            </Select.Option>
          ))}
        </Select>
      </div>
    );


    // @ts-ignore
    const isPersonalizeMenuVisible = (paramKey: any) => personalizeMenuVisibility[paramKey] || false;


    const chatContainerStyle: React.CSSProperties = {
      margin: '20px auto',
      padding: '10px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      background: `url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png')`, // Replace with your image URL
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
    };

    const handleTemplateChange = (templateId: any) => {
      setButtonType('');
      setActionType('');
      setBodyParameters({});
      setIsCarousel(false);
      setDisableNext(false);
      const templateID = templateId?.split('-')[0] || '';
      const selected = templates?.find((template) => template.template_id === templateID);

      if (selected) {
        if (selected?.carousel?.components?.length > 0) {
          setIsCarousel(true);
          setDisableNext(true);
        }
        // @ts-ignore
        setSelectedTemplate(selected);
        if (onSelectedTemplate) {
          onSelectedTemplate(selected);
        }
        const quickReplyButtons = selected?.buttons?.button_quick_reply || [];
        const phoneButtons = selected?.buttons?.button_phone_number || [];
        const urlButtons = selected?.buttons?.button_url || [];
        const copyCodeButtons = selected?.buttons?.button_copy_code || [];

        const quickReplyTextValues = quickReplyButtons.map((button) => button.button_text_quick_reply || '');
        const phoneTextValues = phoneButtons.map((button) => button.button_text_phone_number || '');
        const urlTextValues = urlButtons.map((button) => button.button_text_url || '');
        const copyCodeTextValues = copyCodeButtons.map((button) => button.button_text_copy_code || '');

        setButtonTextValues(quickReplyTextValues);
        setButtonPhoneTextValues(phoneTextValues);
        setButtonUrlTextValues(urlTextValues);
        setButtonCopyCodeTextValues(copyCodeTextValues);

        if  (onButtonTexts) {
          onButtonTexts({quickReplyTextValues, phoneTextValues, urlTextValues, copyCodeTextValues});
        }

        const rtlLanguages = ['ar', 'he', 'fa', 'ur']; // List of RTL language codes
        if (rtlLanguages.includes(selected?.template_language)) {
          setInputDirection('rtl');
          if (onInputDirection) {
            onInputDirection('rtl');
          }
        } else {
          setInputDirection('ltr');
          if (onInputDirection) {
            onInputDirection('ltr');
          }
        }

      }
    };

  const showErrorMessage = (messageText: string) => {
    const notificationParam = {
      message: messageText,
      description: ''
    };
    notification.error(notificationParam);
  };

    const fileUploadCustomRequest = (options: any) => {
      const fileType = options.file.type;
      const fileSize = options.file.size / 1024/ 1024;
      const doc = [ "application/pdf", "text/plain", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/vnd.ms-powerpoint","application/vnd.openxmlformats-officedocument.wordprocessingml.document","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","application/vnd.ms-excel"];
      const image = ["image/jpeg", "image/png"];
      const video = ["video/3gp", "video/mp4"];
      if ((doc.includes(fileType) && fileSize > 100) || (image.includes(fileType) && fileSize > 5) || (video.includes(fileType) && fileSize > 16)) {
        antdMessage.error("File size is too large!");
        setUploadingFile(false);
        setFileList([]);
      }
      else{
        setUploadingFile(true);
        const data = new FormData();
        data.append('file', options.file);
        uploadMediaFileService(selectedOriginator, options.file)
          .then(response => {
              if (response.id !== "None") {
                options.onSuccess(response, options.file);
                setUploadingFile(false);
              } else {
                antdMessage.error(`${options.file} file type is not supported.`);
                setUploadingFile(false);
              }
            }
          ).catch(error => {
          messageForm.setFieldsValue({content: {media: {media_url: ''}}});
          showErrorMessage(error.message);
          setUploadingFile(false);
          setFileList([]);
        });
      }
    };

    const onChangeFile = (info: any) => {
      setFileList(info.fileList);
      const {status, response} = info.file;
      if (status === "done") {
        antdMessage.success(`${info.file.name} file uploaded successfully.`);
        messageForm.setFieldsValue({content: {media: {media_url: response.id}}});
      } else if (status === "error") {
        setFileList([]);
        messageForm.setFieldsValue({content: {media: {media_url: ''}}});
        antdMessage.error(`${info.file.name} file upload failed.`);
      } else if (status === "removed") {
        setFileList([]);
        messageForm.setFieldsValue({content: {media: {media_url: ''}}});
      }
    };

    const onSubmitMessageForm = (values: WhatsappCampaignMessageFormModel) => {
      if ((selectedTemplate?.example_body_text) && (Object.keys(selectedTemplate?.example_body_text).length !== Object.keys(bodyParameters).length)) {
        showErrorNotification("Please enter all body parameters");
      } else {
        const templateId = values.content?.template_id?.split('-')[0] || '';
        const templateLanguage = values.content?.template_id?.split('-')[1] || '';
        const WhatsappContent = {
          campaign_name: values.campaign_name || '',
          originator: values.originator || '',
          content: {
            template_id: templateId || '',
            language: templateLanguage || '',
            body_parameter_values: bodyParameters || selectedTemplate.example_body_text,
            // eslint-disable-next-line no-nested-ternary
            media: selectedTemplate.header_format
              ? (selectedTemplate.header_format === 'TEXT' && selectedTemplate.example_header_text === [])
                ? undefined // Set media to null if TEXT format and no example_header_text
                : {
                  media_type: values.content?.media?.media_type || null,
                  media_url: values.content?.media?.media_url || selectedTemplate.media_url,
                  text_header_title:
                    selectedTemplate.header_format === 'TEXT'
                      ? values.content?.media?.text_header_title || null
                      : '',
                  document_file_name:
                    selectedTemplate.header_format === 'DOCUMENT'
                      ? values.content?.media?.document_file_name || null
                      : '',
                  media_caption: values.content?.media?.media_caption || '',
                  location: selectedTemplate.header_format === 'LOCATION'
                    ? {
                      longitude: values.content?.media?.location?.longitude || '',
                      latitude: values.content?.media?.location?.latitude || '',
                      name: values.content?.media?.location?.name || '',
                      address: values.content?.media?.location?.address || ''
                    } : undefined
                }
              : undefined,
            limited_time_offer: values.content?.limited_time_offer,
            carousel: isCarousel ? carouselComponent : null,
            buttons: values.content?.buttons
          },
        };

        let buttonIndex: number = -1;
        let quickReply = -1;
        selectedTemplate?.buttons?.button_quick_reply?.forEach((button, index) => {
          if (button) {
            quickReply += 1;
            buttonIndex += 1;
            if (values.content?.buttons?.quick_replies[quickReply]?.button_payload) {
              WhatsappContent.content.buttons!.quick_replies[quickReply] = {
                button_index: String(index),
                button_payload: values.content?.buttons?.quick_replies[quickReply]?.button_payload
              };
            } else {
              values.content?.buttons?.quick_replies.splice(quickReply, 1);
              quickReply -= 1;
            }
          }
        });
        if (selectedTemplate?.buttons?.button_phone_number?.length > 0) {
          buttonIndex += 1;
        }
        if (selectedTemplate?.limited_time_offer) {
          if (selectedTemplate?.buttons?.button_copy_code?.length > 0 && selectedTemplate?.buttons?.button_copy_code[0]?.button_text_copy_code) {
            buttonIndex += 1;
            WhatsappContent.content.buttons!.coupon_code = [{
              index: String(buttonIndex),
              coupon_code: values.content?.buttons?.coupon_code[0]?.coupon_code
            }];
          }
          selectedTemplate?.buttons?.button_url?.forEach((button, index) => {
            if (button && button.example_url_values) {
              buttonIndex += 1;
              if (values.content?.buttons?.actions[index]?.action_payload) {
                WhatsappContent.content.buttons!.actions[index] = {
                  action_index: String(buttonIndex),
                  action_type: "URL",
                  action_payload: values.content?.buttons?.actions[index]?.action_payload
                };
              }
            } else {
              buttonIndex += 1;
            }
          });
        } else {
          selectedTemplate?.buttons?.button_url?.forEach((button, index) => {
            if (button && button.example_url_values) {
              buttonIndex += 1;
              if (values.content?.buttons?.actions[index]?.action_payload) {
                WhatsappContent.content.buttons!.actions[index] = {
                  action_index: String(buttonIndex),
                  action_type: "URL",
                  action_payload: values.content?.buttons?.actions[index]?.action_payload
                };
              }
            } else {
              buttonIndex += 1;
            }
          });
          if (selectedTemplate?.buttons?.button_copy_code?.length > 0 && selectedTemplate?.buttons?.button_copy_code[0]?.button_text_copy_code) {
            buttonIndex += 1;
            WhatsappContent.content.buttons!.coupon_code = [{
              index: String(buttonIndex),
              coupon_code: values.content?.buttons?.coupon_code[0]?.coupon_code
            }];
          }
        }

        // Check the header format and set media_type accordingly
        if (selectedTemplate.header_format === 'IMAGE') {
          WhatsappContent.content.media!.media_type = 'image';
        } else if (selectedTemplate.header_format === 'VIDEO') {
          WhatsappContent.content.media!.media_type = 'video';
        } else if (selectedTemplate.header_format === 'DOCUMENT') {
          WhatsappContent.content.media!.media_type = 'document';
        } else if (selectedTemplate.header_format === 'AUDIO') {
          WhatsappContent.content.media!.media_type = 'audio';
        } else if (selectedTemplate.header_format === 'LOCATION') {
          WhatsappContent.content.media!.media_type = 'location';
        } else if (
          selectedTemplate.header_format === 'TEXT' &&
          Object.keys(selectedTemplate.example_header_text).length === 0
        ) {
          WhatsappContent.content.media = undefined;
        } else if (selectedTemplate.header_format === 'TEXT') {
          WhatsappContent.content.media!.media_type = 'text';
          WhatsappContent.content.media!.text_header_title =
            selectedTemplate.example_header_text &&
            values.content?.media?.text_header_title
              ? values.content?.media?.text_header_title
              : null;
        }
        // @ts-ignore
        onClickNext(WhatsappContent);
      }
    };


    const fetchTemplates = async (number: string) => {
      setLoading(true);
      try {
        const fetchedTemplates: any = await listWhatsappMetaMarketingTemplateService(number);
        setTemplates(fetchedTemplates);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // console.error('Error fetching templates:', error);
      }
    };


    const handleOriginatorChange = (value: any) => {
      setSelectedOriginator(value);
      fetchTemplates(value);
    };

    useEffect(() => {
      if (whatsappCampaign) {
        messageForm.setFieldsValue({
          content: {
            template_id: whatsappCampaign.content?.template_id || '',
            body_parameter_values: bodyParameters || '',
            media: {
              media_type: whatsappCampaign.content?.media?.media_type || '',
              media_url: whatsappCampaign.content?.media?.media_url || '',
              text_header_title: whatsappCampaign.content.media?.text_header_title || '',
              document_file_name: whatsappCampaign.content.media?.document_file_name || '',
              media_caption: whatsappCampaign.content?.media?.media_caption || '',
              location: {
                longitude: whatsappCampaign.content?.media?.location?.longitude || '',
                latitude: whatsappCampaign.content?.media?.location?.latitude || '',
                name: whatsappCampaign.content?.media?.location?.name || '',
                address: whatsappCampaign.content?.media?.location?.address || ''
              }
            },
          }
        });
      }
      // fetchTemplates(originator);
      fetchPhoneNumber();
    }, [whatsappCampaign, originator]);

    const renderUploadFormItem = (label: any, accept: any) => (
      <Form.Item
        label={
          <span>
        {label}
            <Tooltip className="mx-1"
                     title="To help us understand what kind of message you want to send, you have the option to provide specific content examples for your template. Make sure not to include any actual user or customer information, and provide only sample content in your examples.">
          <InfoCircleOutlined/>
        </Tooltip>
      </span>
        }
        labelCol={{span: 7}}
        style={{flexDirection: 'row'}}

        name={['content', 'media', 'media_url']}
        rules={[{required: true, message: 'Please upload a file'}]}
      >
        <Upload
          name="file"
          accept={accept}
          maxCount={1}
          customRequest={fileUploadCustomRequest}
          onChange={onChangeFile}
          fileList={fileList}
          showUploadList={{showRemoveIcon: true, removeIcon: <DeleteOutlined/>}}
        >
          <Button type="default" className="bg-gray-lightest mx-2 px-5 w-100">
            <CloudUploadOutlined/> Upload
          </Button>
        </Upload>
      </Form.Item>
    );

    return (

      <Form
        layout="vertical"
        size="small"
        form={messageForm}
        onFinish={onSubmitMessageForm}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={1} lg={1}/>
          <Col xs={24} sm={24} md={15} lg={15}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  label="Whatsapp Campaign Name"
                  name="campaign_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter campaign name!',
                    },
                  ]}
                >
                  <Input/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item label="Originator" name='originator' rules={[
                  {
                    required: true,
                    message: 'Please select your originator!',
                  },
                ]} labelCol={{span: 7}}>
                  <Select loading={loading} placeholder="Choose your Originator" showSearch
                          filterOption={(input: any, option: any) =>
                            (option?.key && option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0) ||
                            (option?.title && option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                          }
                          onChange={handleOriginatorChange}
                  >
                    {originatorNummbers.map((originatorNumber, index) => (
                      <Select.Option key={originatorNumber.number} value={originatorNumber.number}>
                        {originatorNumber.number}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item
                  className="text-left">

                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item label="Choose your template" name={['content', 'template_id']} labelCol={{span: 7}}
                               rules={[
                                 {
                                   required: true,
                                   message: 'Please select your template!',
                                 },
                               ]}>
                      <Select placeholder="Choose your template" onChange={handleTemplateChange} showSearch
                              filterOption={(input: any, option: any) =>
                                (option?.key && option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0) ||
                                (option?.title && option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0) ||
                                (option?.value && option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                              }>
                        {templates?.length === 0 ? (
                          <Select.Option value="addTemplate"><a href="/whatsapp-meta/whatsapp-meta-template">Create New
                            template</a></Select.Option>
                        ) : (
                          templates?.map((template, index) => (
                            <Select.Option key={template.id}
                                           value={`${template.template_id}-${template.template_language}`}>
                              {template.template_name} ({template.template_language})
                            </Select.Option>

                          ))
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  {selectedTemplate && (
                    <>
                      <Col xs={24} sm={24} md={24} className='mt-5'>
                        {selectedTemplate.header_format === 'IMAGE' && renderUploadFormItem('Upload Image Header', '.jpeg, .png')}
                        {selectedTemplate.header_format === 'VIDEO' && renderUploadFormItem('Upload Video Header', '.3gp, .mp4')}
                        {selectedTemplate.header_format === 'DOCUMENT' && renderUploadFormItem('Upload Document Header', '.txt, .xls, .xlsx, .doc, .docx, .ppt, .pptx, .pdf')}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        {selectedTemplate.header_format && selectedTemplate.header_format === 'DOCUMENT' && (
                          <Form.Item
                            label="File Name"
                            name={['content', 'media', 'document_file_name']}
                            labelCol={{span: 7}}
                          >
                            <Input/>
                          </Form.Item>
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        {selectedTemplate.header_format && selectedTemplate.header_format === 'TEXT' && Object.keys(selectedTemplate.example_header_text).length > 0 && (
                          <Form.Item
                            label="Header Parameters"
                            name={['content', 'media', 'text_header_title']}
                            labelCol={{span: 7}}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Header text!',
                              },
                            ]}
                          >
                            <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                              <Input
                                value={parameterValue}
                                onChange={(e) => handleParameterChange(e.target.value)}
                                dir={inputDirection}
                              />
                              <div className="text-right">
                                <Dropdown
                                  overlay={personalizeMenu}
                                  onVisibleChange={() => setShowPersonalizationMenu(!showPersonalizationMenu)}
                                  visible={showPersonalizationMenu}
                                  placement="topRight"
                                >
                                  <Button type="link">
                                    Personalize
                                  </Button>
                                </Dropdown>
                              </div>
                            </div>
                          </Form.Item>
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        {selectedTemplate.header_format === 'LOCATION' && (
                          <Row gutter={16}>
                            <Col xs={24} sm={24} md={12} lg={12}>
                              <Form.Item label="Longitude" name={['content', 'media', 'location', 'longitude']}
                                         labelCol={{span: 7}} rules={[
                                {
                                  required: true,
                                  message: 'Please enter longitude!',
                                },
                              ]}>
                                <Input/>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                              <Form.Item label="Latitude" name={['content', 'media', 'location', 'latitude']}
                                         labelCol={{span: 7}} rules={[
                                {
                                  required: true,
                                  message: 'Please enter latitude!',
                                },
                              ]}>
                                <Input/>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                              <Form.Item label="Name" name={['content', 'media', 'location', 'name']}
                                         labelCol={{span: 7}} rules={[
                                {
                                  required: true,
                                  message: 'Please enter name!',
                                },
                              ]}>
                                <Input/>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                              <Form.Item label="Address" name={['content', 'media', 'location', 'address']}
                                         labelCol={{span: 7}} rules={[
                                {
                                  required: true,
                                  message: 'Please enter address!',
                                },
                              ]}>
                                <Input/>
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        {selectedTemplate.body_text && selectedTemplate.example_body_text && Object.keys(selectedTemplate.example_body_text).length > 0 && (
                          <Form.Item
                            label="Body Parameters"
                            name={['content', 'body_parameter_values']}
                            labelCol={{span: 7}}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter body parameter values!',
                              }
                            ]}
                          >
                            <div>
                              {Object.keys(selectedTemplate.example_body_text).map((key, index) => (
                                <div key={key} style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                                  <span style={{marginRight: '10px'}}>{`${key}:`}</span>
                                  <Input
                                    value={bodyParameters[key] || ''}
                                    onChange={(e) => handleBodyParameterChange(key, e.target.value)}
                                    dir={inputDirection}/>
                                  <div className="text-right">
                                    <Dropdown
                                      overlay={createPersonalizeMenu(key)}
                                      onVisibleChange={(visible) => handlePersonalizeMenuVisibilityChange(key, visible)}
                                      visible={isPersonalizeMenuVisible(key)}
                                      placement="topRight"
                                    >
                                      <Button type="link">
                                        Personalize
                                      </Button>
                                    </Dropdown>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Form.Item>
                        )}
                      </Col>
                      {
                        selectedTemplate?.limited_time_offer?.has_expiration && (
                          <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Item label="Expiration DateTime(UTC)"
                                       name={['content', 'limited_time_offer', 'expiration_time_ms']} labelCol={{span: 7}}
                                       rules={[
                                         {
                                           required: true,
                                           message: 'Please enter future datetime!',
                                         }
                                       ]}>
                              <DatePicker disabledDate={disabledDate} showTime format="YYYY-MM-DD HH:mm:ss"
                                          className='w-100'/>
                            </Form.Item>
                          </Col>
                        )
                      }
                      {selectedTemplate.buttons ? (
                          <>
                          {(selectedTemplate.buttons?.button_quick_reply?.length > 0) && (
                            <Col xs={24} sm={24} md={24} lg={24} style={{display: 'none'}}>
                                <Form.Item /* eslint-disable react/no-array-index-key */>
                                  {selectedTemplate.buttons?.button_quick_reply.map((button, index) => (
                                    <div key={index}>
                                      {button.button_text_quick_reply && (
                                        <Form.Item label={`Quick Reply ${index + 1}`}
                                                   name={['content', 'buttons', 'quick_replies', index, 'button_payload']}
                                                   labelCol={{span: 7}}>
                                          <Input dir={inputDirection}/>
                                        </Form.Item>
                                      )}
                                    </div>
                                  ))}
                                </Form.Item>
                            </Col>
                          )}
                          {(selectedTemplate.buttons?.button_url?.length > 0) && (
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Form.Item /* eslint-disable react/no-array-index-key */>
                                  {selectedTemplate.buttons?.button_url.map((button, index) => (
                                    <div key={index}>
                                      {button.example_url_values && (
                                        <Form.Item label="Dynamic URL Variable"
                                                   name={['content', 'buttons', 'actions', index, 'action_payload']}
                                                   labelCol={{span: 7}}
                                                   rules={[
                                                     {
                                                       required: true,
                                                       message: 'Please enter Buttons Dynamic URL!',
                                                     },
                                                   ]}>
                                          <Input/>
                                        </Form.Item>
                                      )}
                                    </div>
                                  ))}
                                </Form.Item>
                            </Col>
                          )}
                          {(selectedTemplate.buttons?.button_copy_code?.length > 0) && (
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Form.Item /* eslint-disable react/no-array-index-key */>
                                  {selectedTemplate.buttons?.button_copy_code.map((button, index) => (
                                    <div key={index}>
                                      {button.button_text_copy_code && (
                                        <Form.Item label="Coupon Code"
                                                   name={['content', 'buttons', 'coupon_code', 0, 'coupon_code']}
                                                   labelCol={{span: 7}}
                                                   rules={[
                                                     {
                                                       required: true,
                                                       message: 'Please enter valid code!',
                                                     },
                                                     {
                                                       max: 15,
                                                       message: 'Offer code cannot exceed 16 characters',
                                                     }
                                                   ]}>
                                          <Input/>
                                        </Form.Item>
                                      )}
                                    </div>
                                  ))

                                  }
                                </Form.Item>
                            </Col>
                          )}
                          </>)
                        : []}
                      {isCarousel &&
                        <Col xs={24} sm={24} md={24} lg={24}>
                          <CampaignDynamicCarouselComponent template={selectedTemplate}
                                                            onFinishedCard={onCarouselSave} inputDirection={inputDirection} selectedOriginator={selectedOriginator}/></Col>
                      }
                    </>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col lg={1}/>
          <Col xs={15} sm={10} lg={5} md={5} style={chatContainerStyle}>
            <ChatPreviewPage headerText={selectedTemplate.header_text} bodyText={selectedTemplate.body_text}
                             footerText={selectedTemplate.footer_text}
                             selectedMediaType={selectedTemplate.header_format}
                             buttonType={buttonType}
                             optOutButton={false}
                             actionType={actionType}
                             actionButtonsQuickReply={buttonTextValues}
                             actionButtonsPhoneNumber={buttonPhoneTextValues}
                             actionButtonsUrl={buttonUrlTextValues}
                             buttonCopyCodeTextValues={buttonCopyCodeTextValues}
                             isCarousel={isCarousel}
                             inputDirection={inputDirection}/>
          </Col>
          <Col xs={24} sm={24} md={1} lg={1}/>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={23}>
            <div className="text-right" style={{marginTop: '4px', ...(window.innerWidth >= 400 && {marginTop: '100px'})} as any}>
              <Button type="primary" className="ml-2" htmlType="submit" loading={loading} disabled={disableNext || uploadingFile}>
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </Form>)
      ;
  }
;

WhatsappCampaignMessageTab.defaultProps = {
  whatsappCampaign: undefined,
  onSelectedTemplate: undefined,
  onButtonTexts: undefined,
  onInputDirection: undefined,
};

export default WhatsappCampaignMessageTab;
