import React, { useState } from "react";
import {
  Modal, Tooltip
} from "antd";
import { ColumnsType } from "antd/es/table";
import { Contact } from "models";
import TableList from "components/shared/TableList";
import {SafetyOutlined} from "@ant-design/icons";


export interface CampaignContactPickerProps {
  originator?: any;
  contacts: Contact[];
  visible: boolean;
  onClose?: () => void;
  total: number;
  loading?: boolean;
  onPaginate?: (page: number, pageSize: number, searchText?: string) => void;
  selectedContacts?: Contact[];
  onOk?:(records: Contact[]) => void;
}



const CampaignContactPicker = (props: CampaignContactPickerProps) => {
  const { originator, contacts, visible, onClose, total, onPaginate, loading, selectedContacts, onOk } = props;
  const [searchText, setSearchText] = useState<string | undefined>();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [contactData, setContactData] = useState<Contact[]>([]);

  const tableColumns: ColumnsType<Contact> = [
    {
      title: 'Mobile',
      dataIndex: 'number',
      render: (_v, record) => <><span className={`flag:${record.country?.toUpperCase()} mr-2`} />{record.number ?? ''} {(record.blacklist || ((record.contact_whatsapp_opt_out ?? []).length>0 && record.contact_whatsapp_opt_out?.some(item => item.whatsapp_number === originator?.number)))? <Tooltip title={(record.contact_whatsapp_opt_out ?? []).length>0?"Opt outed by Recipient":"Blacklisted Contact"}><SafetyOutlined/></Tooltip>:null}</>
    },
    {
      title: 'Name',
      dataIndex: 'first_name',
      render: (_value, record) => `${record.first_name} ${record.last_name ?? ''}`
    },
    {
      title: 'Group',
      render: (_v, record) => (
        record.groups?.map(g => <div key={`${record.id}_${g.name}`}><span>{g.name}</span><br /><br /></div>)
      )
    },
  ];

  const onSearch = (value: string) => {
    setSearchText(value);
    onPaginate?.(1, pageSize, value);
  };

  const onChangePage = (newPage: number, newSize: number) => {
    if (pageSize !== newSize) {
      setPageSize(newSize);
      setPage(1);
      onPaginate?.(1, newSize, searchText);
    } else {
      setPage(newPage);
      onPaginate?.(newPage, newSize, searchText);
    }
  };

  const getPaginationOption = () => ({
    onChangePage,
    pageSize,
    currentPage: page,
    total
  });

  const onCloseModal = () => {
    setPage(1);
    setSearchText('');
    onClose?.();
  };
  const onChangeSelection = (keys: React.Key[], records: Contact[]) => {
    setContactData(records);
  };

  const onOok=()=>{
    onOk?.(contactData);
  };

  return (
    <Modal
      visible={visible}
      onOk={onOok}
      okText="Add"
      onCancel={onCloseModal}
      // footer={null}
      width={600}
    >
      <TableList<Contact>
        items={contacts}
        columns={tableColumns}
        loading={loading}
        rowKey={(record: Contact) => record.id}
        showSearch
        onSearch={onSearch}
        serverSidePaginationOption={getPaginationOption()}
        scroll={{ y: 400 }}
        onChangeSelection={onChangeSelection}
        persistedSelectedRowKeys={selectedContacts?.map(x => x.id)}

      />
    </Modal>
  );
};

CampaignContactPicker.defaultProps = {
  originator:undefined,
  onClose: undefined,
  loading: false,
  onPaginate: undefined,
  selectedContacts: [],
  onOk:undefined
};

export default CampaignContactPicker;
