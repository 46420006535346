import React, {useEffect, useState} from 'react';
import {
  Alert,
  Button, Card, Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select
} from "antd";
import {CloseOutlined, InfoCircleOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";
import OtpButtonComponents from "./OtpButtonComponents";
import LTOButtonComponents from "./LTOButtonComponents";
import DynamicCarouselComponent from "./DynamicCarouselComponent";
import {WhatsappTemplateDetails} from "../../../../../models";
import translatedSentence from "../translation";


export interface StepThreeProps {
  templateType?: any;
  categoryType?: any;
  onOtpTypeChange: any;
  hasExpiring?: any;
  onButtonTypeChange?: any;
  onActionTypeChange?: any;
  onButtonTextValues?: any;
  onButtonPhoneTextValues?: any;
  onButtonUrlTextValues?: any;
  onButtonPhoneValues?: any;
  onButtonUrlValues?: any;
  onButtonExampleUrlValues?: any;
  onButtonCopyCodeTextValues?: any;
  onAddNewCardChanged?: any;
  onMainSave?: any;
  form?: any;
  editItem?: WhatsappTemplateDetails;
  inputDirection?: any;
  onOptOutButtonChange?: any;
  language?: string;
}

const StepThree = (props: StepThreeProps) => {

  const {
    form,
    templateType,
    categoryType,
    onOtpTypeChange,
    hasExpiring,
    onButtonTypeChange,
    onActionTypeChange,
    onButtonTextValues,
    onButtonPhoneTextValues,
    onButtonUrlTextValues,
    onButtonCopyCodeTextValues,
    onAddNewCardChanged,
    editItem,
    onMainSave,
    onButtonPhoneValues,
    onButtonExampleUrlValues,
    onButtonUrlValues, inputDirection,
    onOptOutButtonChange,
    language
  } = props;
  const [errorMessage] = useState('');
  const [buttonsType, setButtonsType] = useState('');
  const [actionsType, setActionsType] = useState('');
  const [buttonTextValues, setButtonTextValues] = useState<string[]>([]);
  const [buttonPhoneTextValues, setButtonPhoneTextValues] = useState<string[]>([]);
  const [buttonUrlTextValues, setButtonUrlTextValues] = useState<string[]>([]);
  const [buttonUrlValues, setButtonUrlValues] = useState<string[]>([]);
  const [buttonCopyCodeTextValues, setButtonCopyCodeTextValues] = useState<string[]>([]);
  const [fieldsAction, setFieldsAction] = useState([{key: 'initial_key'}]);
  const [buttonPhoneValues, setButtonPhoneValues] = useState<string[]>([]);
  const [buttonExampleUrlValues, setButtonExampleUrlValues] = useState<string[]>([]);
  const [optOutQuickReply, setOptOutQuickReply] = useState(false);
  const [showOptOutError, setShowOptOutError] = useState(false);

  const handleButtonTypeChange = (value: string) => {
    setButtonsType(value);
    if (onButtonTypeChange) {
      onButtonTypeChange(value);
    }
  };

  const handleActionTypeChange = (value: any) => {
    setActionsType(value);
    if (onActionTypeChange) {
      onActionTypeChange(value);
    }
  };

  const handleButtonTextChange = (index: any, value: any) => {
    const newButtonTextValues = [...buttonTextValues];
    newButtonTextValues[index] = value;
    setButtonTextValues(newButtonTextValues);
    if (onButtonTextValues) {
      onButtonTextValues(newButtonTextValues);
    }
  };

  const handleRemoveButtonText = (remove: any, index: any) => {
    remove(index);
    const updatedButtonTextValues = [...buttonTextValues];
    updatedButtonTextValues.splice(index, 1);
    setButtonTextValues(updatedButtonTextValues);
    if (onButtonTextValues) {
      onButtonTextValues(updatedButtonTextValues);
    }
  };

  const handleButtonPhoneTextChange = (index: any, value: any) => {
    const newButtonPhoneTextValues = [...buttonPhoneTextValues];
    newButtonPhoneTextValues[index] = value;
    setButtonPhoneTextValues(newButtonPhoneTextValues);
    if (onButtonPhoneTextValues) {
      onButtonPhoneTextValues(newButtonPhoneTextValues);
    }
  };

  const handleRemoveButtonPhoneText = (remove: any, index: any) => {
    remove(index);
    const updatedButtonPhoneTextValues = [...buttonPhoneTextValues];
    updatedButtonPhoneTextValues.splice(index, 1);
    setButtonPhoneTextValues(updatedButtonPhoneTextValues);
    if (onButtonPhoneTextValues) {
      onButtonPhoneTextValues(updatedButtonPhoneTextValues);
    }
  };

  const handleButtonUrlTextChange = (index: any, value: any) => {
    const newButtonUrlTextValues = [...buttonUrlTextValues];
    newButtonUrlTextValues[index] = value;
    setButtonUrlTextValues(newButtonUrlTextValues);
    if (onButtonUrlTextValues) {
      onButtonUrlTextValues(newButtonUrlTextValues);
    }
  };

  const handleRemoveButtonUrlText = (remove: any, index: any) => {
    remove(index);
    const updatedButtonUrlTextValues = [...buttonUrlTextValues];
    updatedButtonUrlTextValues.splice(index, 1);
    setButtonUrlTextValues(updatedButtonUrlTextValues);
    if (onButtonUrlTextValues) {
      onButtonUrlTextValues(updatedButtonUrlTextValues);
    }
  };

  const handleButtonPhoneChange = (index: any, value: any) => {
    const newButtonTextValues = [...buttonPhoneValues];
    newButtonTextValues[index] = value;
    setButtonPhoneValues(newButtonTextValues);
    if (onButtonPhoneValues) {
      onButtonPhoneValues(newButtonTextValues);
    }
  };

  const handleButtonUrlChange = (index: any, value: any) => {
    const newButtonTextValues = [...buttonUrlValues];
    newButtonTextValues[index] = value;
    setButtonUrlValues(newButtonTextValues);
    if (onButtonUrlValues) {
      onButtonUrlValues(newButtonTextValues);
    }
  };

  const handleButtonExampleUrlChange = (index: any, value: any) => {
    const newButtonTextValues = [...buttonExampleUrlValues];
    newButtonTextValues[index] = value;
    setButtonExampleUrlValues(newButtonTextValues);
    if (onButtonExampleUrlValues) {
      onButtonExampleUrlValues(newButtonTextValues);
    }
  };

  const handleButtonCopyCodeTextChange = (index: any, value: any) => {
    const updatedValues = [...buttonCopyCodeTextValues];
    updatedValues[index] = value;
    setButtonCopyCodeTextValues(updatedValues);
    if (onButtonCopyCodeTextValues) {
      onButtonCopyCodeTextValues([value]);
    }
  };


  const handleRemoveButtonCopyCodeText = (remove: any, index: any) => {
    remove(index);
    const updatedButtonCopyCodeTextValues = [...buttonCopyCodeTextValues];
    updatedButtonCopyCodeTextValues.splice(index, 1);
    setButtonCopyCodeTextValues(updatedButtonCopyCodeTextValues);
    if (onButtonCopyCodeTextValues) {
      onButtonCopyCodeTextValues([]);
    }
  };

  const addField = () => {
    const newField = {key: `new_key_${fieldsAction.length}`};
    setFieldsAction([...fieldsAction, newField]);
  };

  const removeField = (index: number) => {
    const newFields = [...fieldsAction];
    newFields.splice(index, 1);
    setFieldsAction(newFields);

    const newPhoneTextValues = [...buttonPhoneTextValues];
    newPhoneTextValues.splice(index, 1);
    setButtonPhoneTextValues(newPhoneTextValues);
    if (onButtonPhoneTextValues) {
      onButtonPhoneTextValues(newPhoneTextValues);
    }

    const newUrlTextValues = [...buttonUrlTextValues];
    newUrlTextValues.splice(index, 1);
    setButtonUrlTextValues(newUrlTextValues);
    if (onButtonUrlTextValues) {
      onButtonUrlTextValues(newUrlTextValues);
    }
    form.setFieldsValue({buttons: {buttons_type:"", button_phone_number:null, button_url: null}});
  };

  useEffect(() => {
    if (editItem) {

      form.setFieldsValue({
        buttons: editItem.buttons,
      });

      if (editItem.buttons?.button_quick_reply) {
        setButtonsType('QUICK_REPLY');
        const buttonTextEditValue: string[] = editItem.buttons?.button_quick_reply.map((button: any) => button.button_text_quick_reply);
        setButtonTextValues(buttonTextEditValue);
      }
      if (editItem.buttons?.button_phone_number) {
        setActionsType('PHONE_NUMBER');
        setButtonsType('CALL_TO_ACTION');
        form.setFieldsValue({buttons: {buttons_type: "PHONE_NUMBER"}});
        const buttonTextEditPhoneValues: string[] = editItem.buttons?.button_phone_number.map((button: any) => button.button_text_phone_number);
        setButtonPhoneTextValues(buttonTextEditPhoneValues);
      }
      if (editItem.buttons?.button_url) {
        setActionsType('URL');
        setButtonsType('CALL_TO_ACTION');
        form.setFieldsValue({buttons: {buttons_type: "URL"}});
        const buttonTextEditUrlValues: string[] = editItem.buttons?.button_url.map((button: any) => button.button_text_url);
        setButtonUrlTextValues(buttonTextEditUrlValues);
      }
      if (editItem.buttons?.button_copy_code) {
        setButtonsType('COPY_CODE');
        const buttonTextEditCopyCodeValues: string[] = editItem.buttons?.button_copy_code.map((button: any) => button.button_text_copy_code);
        setButtonCopyCodeTextValues(buttonTextEditCopyCodeValues);
      }
      if (editItem.buttons?.button_url && editItem.buttons?.button_phone_number) {
        setActionsType('PHONE_NUMBER_AND_URL');
        setButtonsType('CALL_TO_ACTION');
        form.setFieldsValue({buttons: {buttons_type: "PHONE_NUMBER_AND_URL"}});
        const buttonTextEditUrlValues: string[] = editItem.buttons?.button_url.map((button: any) => button.button_text_url);
        const buttonTextEditPhoneValues: string[] = editItem.buttons?.button_phone_number.map((button: any) => button.button_text_phone_number);
        setButtonPhoneTextValues(buttonTextEditPhoneValues);
        setButtonUrlTextValues(buttonTextEditUrlValues);
      }
    }

    if (form.getFieldValue(['buttons', 'button_quick_reply'])) {
      setButtonsType('QUICK_REPLY');
    }
    if (form.getFieldValue(['buttons', 'button_phone_number'])) {
      setActionsType('PHONE_NUMBER');
      setButtonsType('CALL_TO_ACTION');
    }
    if (form.getFieldValue(['buttons', 'button_url'])) {
      setActionsType('URL');
      setButtonsType('CALL_TO_ACTION');
    }
    if (form.getFieldValue(['buttons', 'button_copy_code'])) {
      setButtonsType('COPY_CODE');
    }
    if ((form.getFieldValue(['buttons', 'button_url'])) && (form.getFieldValue(['buttons', 'button_phone_number']))){
      setActionsType('PHONE_NUMBER_AND_URL');
      setButtonsType('CALL_TO_ACTION');
    }
  }, [editItem, form]);

  const handleOptOutQuickReplyType = async () =>{
    setOptOutQuickReply(true);
    setShowOptOutError(false);
    onOptOutButtonChange(true, false);
  };

  const removeOptOutQuickReplyType = () =>{
    setOptOutQuickReply(false);
    setShowOptOutError(true);
    onOptOutButtonChange(false, false);
  };

  const handleOptOutCheckbox = (check: boolean) =>{
    setShowOptOutError(check);
    onOptOutButtonChange(true, check);
  };

  return (

    <Row gutter={24}>
      {['AUTHENTICATION', 'LIMITED_TIME_OFFER', 'CAROUSEL'].indexOf(templateType) === -1 && (
        <>
          <Col xs={24} sm={24} md={24}>
            <p style={{textAlign: 'left'}}>
              If you want any button in the template, choose one of them. Otherwise, Save and
              Continue:
            </p>
          </Col>

          <Col xs={24} sm={24} md={24}>
            <Form.Item labelAlign='left' labelCol={{span: 3}} style={{textAlign: 'center'}}>
              <Button.Group>
                <Button
                  type={buttonsType === 'QUICK_REPLY' ? 'primary' : 'default'}
                  onClick={() => handleButtonTypeChange('QUICK_REPLY')}
                >
                  Quick Replies
                </Button>
                <Button
                  type={buttonsType === 'CALL_TO_ACTION' ? 'primary' : 'default'}
                  onClick={() => handleButtonTypeChange('CALL_TO_ACTION')}
                >
                  Call to Action
                </Button>
                {categoryType !== 'UTILITY' && (
                  <Button
                    type={buttonsType === 'COPY_CODE' ? 'primary' : 'default'}
                    onClick={() => handleButtonTypeChange('COPY_CODE')}
                  >
                    Copy Code
                  </Button>
                )}

                <Button
                  type={buttonsType === 'None' ? 'primary' : 'default'}
                  onClick={() => handleButtonTypeChange('None')}
                >
                  None
                </Button>
              </Button.Group>
            </Form.Item>
          </Col>


          <Col xs={24} sm={24} md={24}>
            {buttonsType === 'None' && (
              <Form.Item labelAlign='left' labelCol={{span: 3}} label="">
                <Form.Item labelCol={{span: 3}} label="" name={['buttons', 'buttons_type']}>
                  <Input type="hidden" value="None"/>
                </Form.Item>
              </Form.Item>
            )}
          </Col>
          <Col xs={24} sm={24} md={24}>
            {buttonsType === 'QUICK_REPLY' && (
              <div>
                {((categoryType==="MARKETING") && !editItem)&&
                <Button hidden={optOutQuickReply} onClick={() => handleOptOutQuickReplyType()} className='ml-2 mr-2 mb-3' icon={<PlusOutlined/>}>Marketing Opt-Out Button</Button>}
                {((categoryType==="MARKETING") && optOutQuickReply && !editItem) &&(
                  <Card>
                    <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem'}}>
                      <Button icon={<CloseOutlined/>} onClick={() => removeOptOutQuickReplyType()}/>
                    </div>
                    <Form.Item
                      labelAlign='left'
                      labelCol={{span: 3}}
                      label="Button Text"
                    >
                      <Input disabled
                             value={translatedSentence[language || "en"].stopPromotions}
                      />
                    </Form.Item>
                    <Form.Item
                      labelAlign='left'
                      labelCol={{span: 3}}
                      label="Footer"
                    >
                      <Input disabled
                             value={translatedSentence[language || "en"].notInterested}
                      />
                    </Form.Item>
                    <Form.Item
                      labelAlign='left'
                      labelCol={{span: 3}}
                    >
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      <Checkbox onChange={(e) => handleOptOutCheckbox(e.target.checked)}>I understand that it's 's
                        responsibility to stop sending marketing messages to customers who opt out.</Checkbox>
                      {!showOptOutError &&
                        <Alert message="This box must be checked to add this button." type="error" showIcon/>}
                    </Form.Item>
                  </Card>
                )
                }
                <Form.Item labelAlign='left' labelCol={{span: 3}} label="">
                <Form.List name={['buttons', 'button_quick_reply']}
                           initialValue={['']} // Initialize with an empty string
                >
                  {(fields, {add, remove}) => (
                    <>
                      {fields.map((field, index) => (
                        <div key={field.key}>
                          <Form.Item
                            labelAlign='left'
                            labelCol={{span: 3}}
                            label="Button Text"
                            name={[field.name, 'button_text_quick_reply']}
                            fieldKey={[field.fieldKey, 'button_text_quick_reply']}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Button text',
                              },
                              {
                                max: 25,
                                message: 'Button Text cannot exceed 25 characters',
                              }
                            ]}
                          >
                            <Input placeholder="Enter button text"
                                   value={buttonTextValues[index] || ''}
                                   onChange={(e) => handleButtonTextChange(index, e.target.value)}
                                   dir={inputDirection}
                            />
                          </Form.Item>
                          {/* {index > 0 && ( */}
                          {/*   <Button */}
                          {/*     style={{marginBottom: '12px'}} */}
                          {/*     type="dashed" */}
                          {/*     onClick={() => { */}
                          {/*       remove(field.name); */}
                          {/*       // Update state here to remove the corresponding button text */}
                          {/*       const updatedButtonTextValues = [...buttonTextValues]; */}
                          {/*       updatedButtonTextValues.splice(index, 1); */}
                          {/*       setButtonTextValues(updatedButtonTextValues); */}
                          {/*     }} */}
                          {/*     block */}
                          {/*     icon={<MinusOutlined/>} */}
                          {/*   > */}
                          {/*     Remove Button */}
                          {/*   </Button> */}
                          {/* )} */}
                          {/* {index > 0 && ( */}
                            <Button
                              style={{ marginBottom: '12px' }}
                              type="dashed"
                              onClick={() => handleRemoveButtonText(remove, index)}
                              block
                              icon={<MinusOutlined />}
                            >
                              Remove Button
                            </Button>
                            {/* )} */}
                          </div>
                        ))}
                        {fields.length < 10 && (
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                            Add Button
                          </Button>
                        )}
                      </>
                    )}
                  </Form.List>
                  {errorMessage && <Alert message={errorMessage} type="error" showIcon/>}
                </Form.Item>
              </div>
            )}
          </Col>


          {buttonsType === 'CALL_TO_ACTION' && (
            <>
              <Col xs={24} sm={24} md={24}>
                <Form.Item labelAlign='left' labelCol={{span: 5}} label="Select Action Type"
                           name={['buttons', 'buttons_type']}>
                  <Select placeholder="Select Action type"
                          onChange={(value) => handleActionTypeChange(value)}>
                    <Select.Option value="PHONE_NUMBER">Call phone number</Select.Option>
                    <Select.Option value="URL">Website</Select.Option>
                    <Select.Option value="PHONE_NUMBER_AND_URL">Call phone number & Website</Select.Option>
                  </Select>
                </Form.Item>

                <Col>
                  {actionsType === 'PHONE_NUMBER_AND_URL' && (
                    <>
                      {fieldsAction.map((field, index) => (
                        <div key={field.key}>
                          <Form.Item
                            labelAlign='left'
                            labelCol={{span: 5}}
                            label="Button Text (Phone Number)"
                            name={['buttons', 'button_phone_number', index, 'button_text_phone_number']}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Button text for Phone Number',
                              },
                              {
                                max: 25,
                                message: 'Button Text cannot exceed 25 characters',
                              }
                            ]}
                          >
                            <Input
                              placeholder="Enter button text for phone number"
                              value={buttonPhoneTextValues[index] || ''}
                              onChange={(e) => handleButtonPhoneTextChange(index, e.target.value)}
                              dir={inputDirection}/>
                          </Form.Item>
                          <Form.Item
                            labelAlign='left'
                            labelCol={{span: 5}}
                            label="Phone Number"
                            name={['buttons', 'button_phone_number', index, 'button_phone_number']}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Phone Number',
                              },
                              {
                                validator: (_, value) => {
                                  const phoneNumberRegex = /^\+?[1-9]\d{1,14}$/; // Adjust the regex based on your validation criteria

                                  if (value && !phoneNumberRegex.test(value)) {
                                    return Promise.reject(new Error('Please enter a valid phone number'));
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input placeholder="Enter phone number"
                                   value={buttonPhoneValues[index] || ''}
                                   onChange={(e) => handleButtonPhoneChange(index, e.target.value)}
                                   />
                          </Form.Item>
                          <Form.Item
                            labelAlign='left'
                            labelCol={{span: 5}}
                            label="Button Text (URL)"
                            name={['buttons', 'button_url', index, 'button_text_url']}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Button text for URL',
                              },
                              {
                                max: 25,
                                message: 'Button Text cannot exceed 25 characters',
                              }
                            ]}
                          >
                            <Input
                              placeholder="Enter button text for url"
                              value={buttonUrlTextValues[index] || ''}
                              onChange={(e) => handleButtonUrlTextChange(index, e.target.value)}
                              dir={inputDirection}/>
                          </Form.Item>
                          <Form.Item
                            labelAlign='left'
                            labelCol={{span: 5}}
                            label={<span>
                                                      URL <InfoCircleOutlined
                              style={{fontSize: '16px', marginLeft: '8px', cursor: 'pointer'}}
                              onClick={() => {
                                Modal.info({
                                  title: "URL",
                                  content: (
                                    <>
                                      <div>
                                        If you wants to add this button as <b>Dynamic URL</b> then add it
                                        like <b>https://www.luckyshrub.com/shop?promo={'{{'}1{'}}'}</b> then fill
                                        the <b>example
                                        url value</b>.It is mandatory for <b>Dynamic URL</b>.
                                      </div>
                                      <br/>
                                      <div style={{opacity: '0.5'}}>
                                        (Using a <b>Static URL</b>, you can give direct access via a single click
                                        to your website.You can also set up the button to visit a <b>Dynamic
                                        website</b> with your customer directed to a personalized website.)
                                      </div>
                                    </>
                                  ),
                                });
                              }}/>
                                                  </span>}
                            name={['buttons', 'button_url', index, 'button_url']}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Website URL',
                              },
                              {
                                type: 'url',
                                message: 'Please enter a valid URL',
                              },
                              {
                                max: 2000,
                                message: 'URL must be at most 2000 characters',
                              }
                            ]}
                          >
                            <Input placeholder="Enter URL"
                                   value={buttonUrlValues[index] || ''}
                                   onChange={(e) => handleButtonUrlChange(index, e.target.value)}/>
                          </Form.Item>
                          {buttonUrlValues[index]?.includes('{{1}}') && (
                            <Form.Item
                              labelAlign='left'
                              labelCol={{span: 5}}
                              label={<span>
                                                          Example URL Value <InfoCircleOutlined
                                style={{fontSize: '16px', marginLeft: '8px', cursor: 'pointer'}}
                                onClick={() => {
                                  Modal.info({
                                    title: "Example URL Value",
                                    content: (
                                      <>
                                        <div>
                                          If you wants to add a <b>Dynamic URL</b> button then fill the <b>example
                                          url value</b>.It is mandatory for <b>Dynamic URL</b>.
                                        </div>
                                        <br/>
                                        <div style={{opacity: '0.5'}}>
                                          (Using a <b>Static URL</b>, you can give direct access via a single click
                                          to your website.You can also set up the button to visit a <b>Dynamic
                                          website</b> with your customer directed to a personalized website.)
                                        </div>
                                      </>
                                    ),
                                  });
                                }}/>
                                                      </span>}
                              name={['buttons', 'button_url', index, 'example_url_values']}
                            >

                              <Input placeholder="Enter Example URL"
                                     value={buttonExampleUrlValues[index] || ''}
                                     onChange={(e) => handleButtonExampleUrlChange(index, e.target.value)}/>
                            </Form.Item>)}
                          {/* {index > 0 && ( */}
                            <Button style={{marginBottom: "12px"}} type="dashed" onClick={() => removeField(index)}
                                    block icon={<MinusOutlined/>}>
                              Remove Button
                            </Button>
                          {/* )} */}
                        </div>
                      ))}
                      {fieldsAction.length < 1 && (
                        <Button type="dashed" onClick={addField} block icon={<PlusOutlined/>}>
                          Add Button
                        </Button>
                      )}
                    </>
                  )}
                  {errorMessage && <Alert message={errorMessage} type="error" showIcon/>}
                </Col>


                <Col xs={24} sm={24} md={24}>
                  {actionsType === 'PHONE_NUMBER' && (
                    <Form.Item labelAlign='left' labelCol={{span: 5}} label="">
                      <Form.List name={['buttons', 'button_phone_number']}
                                 initialValue={['']} // Initialize with an empty string
                      >
                        {(fields, {add, remove}) => (
                          <>
                            {fields.map((field, index) => (
                              <div key={field.key}>
                                <Form.Item
                                  labelAlign='left'
                                  labelCol={{span: 5}}
                                  label="Button Text"
                                  name={[field.name, 'button_text_phone_number']}
                                  fieldKey={[field.fieldKey, 'button_text_phone_number']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter Button text',
                                    },
                                    {
                                      max: 25,
                                      message: 'Button Text cannot exceed 25 characters',
                                    }
                                  ]}
                                >
                                  <Input placeholder="Enter button text"
                                         value={buttonPhoneTextValues[index] || ''}
                                         onChange={(e) => handleButtonPhoneTextChange(index, e.target.value)}
                                         dir={inputDirection}/>
                                </Form.Item>
                                <Form.Item
                                  labelAlign='left'
                                  labelCol={{span: 5}}
                                  label="Phone Number"
                                  name={[field.name, 'button_phone_number']}
                                  fieldKey={[field.fieldKey, 'button_phone_number']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter Phone number',
                                    },
                                    {
                                      validator: (_, value) => {
                                        const phoneNumberRegex = /^\+?[1-9]\d{1,14}$/; // Adjust the regex based on your validation criteria

                                        if (value && !phoneNumberRegex.test(value)) {
                                          return Promise.reject(new Error('Please enter a valid phone number'));
                                        }
                                        return Promise.resolve();
                                      },
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter phone number"
                                         value={buttonPhoneValues[index] || ''}
                                         onChange={(e) => handleButtonPhoneChange(index, e.target.value)}/>
                                </Form.Item>
                                {/* {index > 0 && ( */}
                                {/*   <Button */}
                                {/*     style={{marginBottom: "12px"}} */}
                                {/*     type="dashed" */}
                                {/*     onClick={() => { */}
                                {/*       remove(field.name); */}
                                {/*       // Update state here to remove the corresponding button text */}
                                {/*       const updatedPhoneTextValues = [...buttonPhoneTextValues]; */}
                                {/*       updatedPhoneTextValues.splice(index, 1); */}
                                {/*       setButtonPhoneTextValues(updatedPhoneTextValues); */}
                                {/*     }} */}
                                {/*     block */}
                                {/*     icon={<MinusOutlined/>} */}
                                {/*   > */}
                                {/*     Remove Button */}
                                {/*   </Button> */}
                                {/* )} */}

                                {/* {index > 0 && ( */}
                                  <Button
                                    style={{ marginBottom: '12px' }}
                                    type="dashed"
                                    onClick={() => handleRemoveButtonPhoneText(remove, index)}
                                    block
                                    icon={<MinusOutlined />}
                                  >
                                    Remove Button
                                  </Button>
                                {/* )} */}
                              </div>
                            ))}
                            {fields.length < 1 && (
                              <Button type="dashed" onClick={() => add()} block
                                      icon={<PlusOutlined/>}>
                                Add Button
                              </Button>
                            )}
                          </>
                        )}
                      </Form.List>
                      {errorMessage && <Alert message={errorMessage} type="error" showIcon/>}
                    </Form.Item>
                  )}
                </Col>
              </Col><Col xs={24} sm={24} md={24}>
              {actionsType === 'URL' && (
                <Form.Item labelAlign='left' labelCol={{span: 5}} label="">
                  <Form.List name={['buttons', 'button_url']}
                             initialValue={['']} // Initialize with an empty string
                  >
                    {(fields, {add, remove}) => (
                      <>
                        {fields.map((field, index) => (
                          <div key={field.key}>
                            <Form.Item
                              labelAlign='left'
                              labelCol={{span: 5}}
                              label="Button Text"
                              name={[field.name, 'button_text_url']}
                              fieldKey={[field.fieldKey, 'button_text_url']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter Button text',
                                },
                                {
                                  max: 25,
                                  message: 'Button Text cannot exceed 25 characters',
                                }
                              ]}
                            >
                              <Input placeholder="Enter button text"
                                     value={buttonUrlTextValues[index] || ''}
                                     onChange={(e) => handleButtonUrlTextChange(index, e.target.value)}
                                     dir={inputDirection}/>
                            </Form.Item>
                            <Form.Item
                              labelAlign='left'
                              labelCol={{span: 5}}
                              label={<span>
                                                                  URL <InfoCircleOutlined
                                style={{fontSize: '16px', marginLeft: '8px', cursor: 'pointer'}}
                                onClick={() => {
                                  Modal.info({
                                    title: "URL",
                                    content: (
                                      <>
                                        <div>
                                          If you wants to add this button as <b>Dynamic URL</b> then add it
                                          like <b>https://www.luckyshrub.com/shop?promo={'{{'}1{'}}'}</b> then fill
                                          the <b>example
                                          url value</b>.It is mandatory for <b>Dynamic URL</b>.
                                        </div>
                                        <br/>
                                        <div style={{opacity: '0.5'}}>
                                          (Using a <b>Static URL</b>, you can give direct access via a single click
                                          to your website.You can also set up the button to visit a <b>Dynamic
                                          website</b> with your customer directed to a personalized website.)
                                        </div>
                                      </>
                                    ),
                                  });
                                }}/>
                                                              </span>}
                              name={[field.name, 'button_url']}
                              fieldKey={[field.fieldKey, 'button_url']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter Button URL value',
                                },
                                {
                                  type: 'url',
                                  message: 'Please enter a valid URL',
                                },
                                {
                                  max: 2000,
                                  message: 'URL must be at most 2000 characters',
                                }
                              ]}
                            >
                              <Input placeholder="Enter URL"
                                     value={buttonUrlValues[index] || ''}
                                     onChange={(e) => handleButtonUrlChange(index, e.target.value)}/>
                            </Form.Item>
                            {buttonUrlValues[index]?.includes('{{1}}') && (
                              <Form.Item
                                labelAlign='left'
                                labelCol={{span: 5}}
                                label={<span>
                                                                      Example URL Value <InfoCircleOutlined
                                  style={{fontSize: '16px', marginLeft: '8px', cursor: 'pointer'}}
                                  onClick={() => {
                                    Modal.info({
                                      title: "Example URL Value",
                                      content: (
                                        <>
                                          <div>
                                            If you wants to add a <b>Dynamic URL</b> button then fill the <b>example
                                            url value</b>.It is mandatory for <b>Dynamic URL</b>.
                                          </div>
                                          <br/>
                                          <div style={{opacity: '0.5'}}>
                                            (Using a <b>Static URL</b>, you can give direct access via a single
                                            click to your website.You can also set up the button to visit a <b>Dynamic
                                            website</b> with your customer directed to a personalized website.)
                                          </div>
                                        </>
                                      ),
                                    });
                                  }}/>
                                                                  </span>}
                                name={[field.name, 'example_url_values']}
                                fieldKey={[field.fieldKey, 'example_url_values']}
                              >

                                <Input placeholder="Enter Example URL"
                                       value={buttonExampleUrlValues[index] || ''}
                                       onChange={(e) => handleButtonExampleUrlChange(index, e.target.value)}/>
                              </Form.Item>
                            )}

                            {/* {index > 0 && ( */}
                            {/*   <Button */}
                            {/*     style={{marginBottom: "12px"}} */}
                            {/*     type="dashed" */}
                            {/*     onClick={() => { */}
                            {/*       remove(field.name); */}
                            {/*       // Update state here to remove the corresponding button text */}
                            {/*       const updatedUrlTextValues = [...buttonUrlTextValues]; */}
                            {/*       updatedUrlTextValues.splice(index, 1); */}
                            {/*       setButtonUrlTextValues(updatedUrlTextValues); */}
                            {/*     }} */}
                            {/*     block */}
                            {/*     icon={<MinusOutlined/>} */}
                            {/*   > */}
                            {/*     Remove Button */}
                            {/*   </Button> */}
                            {/* )} */}

                            {/* {index > 0 && ( */}
                              <Button
                                style={{ marginBottom: '12px' }}
                                type="dashed"
                                onClick={() => handleRemoveButtonUrlText(remove, index)}
                                block
                                icon={<MinusOutlined />}
                              >
                                Remove Button
                              </Button>
                            {/* )} */}
                          </div>
                        ))}
                        {fields.length < 2 && (
                          <Button type="dashed" onClick={() => add()} block
                                  icon={<PlusOutlined/>}>
                            Add Button
                          </Button>
                        )}
                      </>
                    )}
                  </Form.List>
                  {errorMessage && <Alert message={errorMessage} type="error" showIcon/>}
                </Form.Item>
              )}
            </Col></>
          )}
        </>
      )}
      <Col xs={24} sm={24} md={24}>
        {buttonsType === 'COPY_CODE' && templateType !== 'LIMITED_TIME_OFFER' && (
          <Form.Item labelAlign='left' labelCol={{span: 3}} label="">
            <Form.List name={['buttons', 'button_copy_code']} initialValue={['']}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <div key={field.key}>
                      <Form.Item
                        labelAlign='left'
                        labelCol={{ span: 3 }}
                        label="Example Code"
                        name={[field.name, 'button_text_copy_code']}
                        fieldKey={[field.fieldKey, 'button_text_copy_code']}
                        rules={[
                          {
                            max: 15,
                            message: 'Example Code cannot exceed 15 characters',
                          },
                          {
                            required: true,
                            message: 'Please enter Coupon code to be copied when tapped',
                          },
                          {
                            validator: (_, value) => {
                              if (value && value.includes(' ')) {
                                return Promise.reject(new Error('Spaces are not allowed'));
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter button text"
                          value={buttonCopyCodeTextValues[index] || ''}
                          onChange={(e) => handleButtonCopyCodeTextChange(index, e.target.value)}
                          dir={inputDirection}
                        />
                      </Form.Item>
                      <Button
                        style={{ marginBottom: '12px' }}
                        type="dashed"
                        onClick={() => handleRemoveButtonCopyCodeText(remove, index)}
                        block
                        icon={<MinusOutlined />}
                      >
                        Remove Button
                      </Button>
                    </div>
                  ))}
                  {fields.length < 1 && (
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add Button
                    </Button>
                  )}
                </>
              )}
            </Form.List>
            {errorMessage && <Alert message={errorMessage} type="error" showIcon />}
          </Form.Item>
        )}
      </Col>
      {templateType === 'AUTHENTICATION' && (
        <OtpButtonComponents form={form} onOtpTypeChange={onOtpTypeChange} inputDirection={inputDirection}/>
      )}
      {templateType === 'LIMITED_TIME_OFFER' && (
        <LTOButtonComponents hasExpiring={hasExpiring} inputDirection={inputDirection}/>
      )}
      {templateType === 'CAROUSEL' && (
        <DynamicCarouselComponent form={form} onAddNewCardChanged={onAddNewCardChanged} editItem={editItem}
                                  onMainSave={onMainSave} inputDirection={inputDirection}/>)}
    </Row>
  );
};

StepThree.defaultProps = {
  form: undefined,
  templateType: undefined,
  categoryType: undefined,
  hasExpiring: undefined,
  onButtonTextValues: undefined,
  onButtonPhoneTextValues: undefined,
  onButtonUrlTextValues: undefined,
  onButtonTypeChange: undefined,
  onActionTypeChange: undefined,
  onButtonPhoneValues: undefined,
  onButtonUrlValues: undefined,
  onButtonExampleUrlValues: undefined,
  onButtonCopyCodeTextValues: undefined,
  onAddNewCardChanged: undefined,
  onMainSave: undefined,
  editItem: undefined,
  inputDirection: undefined,
  onOptOutButtonChange: undefined,
  language: undefined
};


export default StepThree;
