import React, {useEffect} from 'react';
import {Card, Col, Row} from 'antd';
import {ImportOutlined, PhoneFilled, CopyOutlined} from '@ant-design/icons';
import ChatMessageComponent from '../WhatsappMessagePreviewComponent';
import translatedSentence from "../WhatsappTemplateForm/translation";

interface MediaIconUrls {
  [key: string]: string;
}

const ChatPreviewPage = ({
                           headerText,
                           language,
                           bodyText,
                           footerText,
                           selectedMediaType,
                           buttonType,
                           actionType,
                           actionButtonsQuickReply,
                           actionButtonsPhoneNumber,
                           actionButtonsUrl,
                           buttonCopyCodeTextValues,
                           isCarousel, inputDirection, templateType,
                           optOutButton
                         }: {
  headerText: string;
  // eslint-disable-next-line react/require-default-props
  language?: string,
  bodyText: string;
  footerText: string;
  selectedMediaType: string;
  buttonType: string;
  actionType: string;
  actionButtonsQuickReply: string[];
  actionButtonsPhoneNumber: string[];
  actionButtonsUrl: string[];
  buttonCopyCodeTextValues: string[];
  isCarousel: boolean,
  // eslint-disable-next-line react/require-default-props
  inputDirection?: any,
  // eslint-disable-next-line react/require-default-props
  templateType?: any,
  optOutButton: boolean
}) => {
  const mediaIconUrls: MediaIconUrls = {
    IMAGE:
      'https://pixsector.com/cache/517d8be6/av5c8336583e291842624.png',
    VIDEO:
      'https://www.pngmart.com/files/1/Video-Icon-PNG-HD.png',
    DOCUMENT:
      'https://www.freeiconspng.com/thumbs/document-icon/document-icon-19.png',
    LOCATION:
      'https://www.freeiconspng.com/thumbs/location-icon-png/location-icon-map-png--1.png',
  };

  // Conditional content based on templateType
  const finalBodyText = templateType === 'AUTHENTICATION'
    ? `__ is your verification code. For your security, do not share this code: ${bodyText}`
    : bodyText?.replace(/\*(.*?)\*/g, '<b>$1</b>')
      .replace(/_(.*?)_/g, '<i>$1</i>')
      .replace(/```(.*?)```/g, '<code>$1</code>')
      .replace(/~(.*?)~/g, '<s>$1</s>');

  const finalFooterText = templateType === 'AUTHENTICATION'
    ? 'This code expires in __ minutes.'
    : footerText;

  const messages = [
    {
      content: headerText,
      isBold: true,
      isMedia: ['IMAGE', 'VIDEO', 'DOCUMENT', 'LOCATION'].includes(selectedMediaType),
      mediaIconUrl: mediaIconUrls[selectedMediaType],
    },
    { content: finalBodyText },
    { content: finalFooterText, isOpacity: true },
  ];

  const chatContainerStyle = {
    maxWidth: '400px',
    margin: '20px auto',
    padding: '10px',
    background: 'white',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  };
  useEffect(() => {
  }, [headerText,
    bodyText,
    footerText,
    selectedMediaType,
    buttonType,
    actionType,
    actionButtonsQuickReply,
    actionButtonsPhoneNumber,
    actionButtonsUrl,
    buttonCopyCodeTextValues,
    isCarousel, inputDirection, templateType, language]);


  return (
    <div>
      {isCarousel ?
        <Row style={chatContainerStyle}>
          <Col xs={24} sm={24} md={24}>
            <p>No Preview available for Carousel Templates</p>
          </Col>
        </Row>
        :
        <>
          <Row style={chatContainerStyle}>
            <Col xs={24} sm={24} md={24} /* eslint-disable react/no-array-index-key */>
              {messages?.map((message, index) => (
                <ChatMessageComponent
                  key={index}
                  content={message.content}
                  isBold={message.isBold}
                  isOpacity={message.isOpacity}
                  isMedia={message.isMedia}
                  mediaIconUrl={message.mediaIconUrl}
                  inputDirection={inputDirection}
                />
              ))}
            </Col>
          </Row>
          {optOutButton && (
            <Row>
              <Col xs={24} sm={24} md={24} className='text-center'>
                <Card style={{marginTop: '-15px', padding: '-20px'}} dir={inputDirection}>
                  {translatedSentence[language || "en"].stopPromotions}
                </Card>
              </Col>
            </Row>
          )}
          {actionButtonsQuickReply?.slice(0, 4)?.map((button, i) => (
            button !== "" && (
            <Row>
              <Col xs={24} sm={24} md={24} key={i} className='text-center'>
                <Card style={{marginTop: '-15px', padding: '-20px'}} dir={inputDirection}>
                  {button}
                </Card>
              </Col>
            </Row>
            )
          ))}
          {templateType === 'AUTHENTICATION' ? (
            <Row>
              <Col xs={24} sm={24} md={24} className="text-center">
                <Card style={{ marginTop: '-15px', padding: '-20px' }} dir={inputDirection}>
                  <CopyOutlined />&nbsp;&nbsp;Copy Code
                </Card>
              </Col>
            </Row>
          ) : (
            buttonCopyCodeTextValues.map((button, i) => (
              button !== "" && (
              <Row key={i} dir={inputDirection}>
                <Col xs={24} sm={24} md={24} key={i} className="text-center">
                  <Card style={{ marginTop: '-15px', padding: '-20px' }} dir={inputDirection}>
                    <CopyOutlined />&nbsp;&nbsp;
                    {button}
                  </Card>
                </Col>
              </Row>
              )
            ))
          )}
          <Row>
            {actionButtonsPhoneNumber?.map((value, index) => (
              value !== "" && (
                <Col xs={24} sm={24} md={24} key={index} className='text-center'>
                  <Card style={{ marginTop: '-15px', padding: '-20px' }} dir={inputDirection}>
                    <PhoneFilled />&nbsp;&nbsp;
                    {value}
                  </Card>
                </Col>
              )
            ))}
          </Row>
          {actionButtonsUrl?.map((button, i) => (
            button !== "" && (
              <Row key={i}>
                <Col xs={24} sm={24} md={24} className='text-center'>
                  <Card style={{ marginTop: '-15px', padding: '-20px' }} dir={inputDirection}>
                    <ImportOutlined />&nbsp;&nbsp;
                    {button}
                  </Card>
                </Col>
              </Row>
            )
          ))}
        </>}
    </div>
  );
};

export default ChatPreviewPage;
