import {
  createWhatsappTemplate,
  deleteWhatsappTemplate,
  getOnboardingDetails,
  getWhatsappPricing,
  getWhatsappSettings,
  getWhatsappSubscription,
  getWhatsappTemplate,
  listMarketingWhatsappTemplate,
  listWhatsappContact,
  listWhatsappTemplate,
  listWhatsappTemplateGettingStarted,
  saveWhatsappToken,
  sendWhatsappOriginatorRegisterRequest,
  sendWhatsappShowInterestRequest,
  updateWhatsappSettings,
  updateWhatsappTemplate
} from "../../api/whatsapp/WhatsappApi";
import {
  InterveneInboxRequest,
  WhatsappApiReportExportRequest,
  WhatsappApiReportFilterRequest,
  WhatsappMetaOnboardRequest,
  WhatsappOriginatorRegisterRequest,
  WhatsappSettingsUpdateRequest,
  WhatsappTemplateCreateRequest,
  WhatsappTemplateDeleteRequest,
  WhatsappTemplateUpdateRequest
} from "../../models/requests";

import {
  createWhatsappMetaTemplate,
  deleteWhatsappMetaTemplate,
  getOriginator,
  getWhatsappMetaBusinessProfile,
  getWhatsappMetaRegisteredNumbers,
  listWhatsappChatsByPhoneNumber,
  listWhatsappChatMessagesByPhoneNumber,
  updateWhatsappMetaDisplayName,
  listWhatsappMetaAuthenticationTemplate,
  listWhatsappMetaMarketingTemplate,
  listWhatsappMetaRegisteredNumbers,
  listWhatsappMetaTemplate,
  listWhatsappMetaWabaId,
  updateWhatsappMetaBusinessProfile,
  updateWhatsappMetaTemplate,
  uploadMediaFile,
  uploadResumableFile,
  whatsappMetaOnboarding,
  getWhatsappChatExport,
  fetchWhatsappMediaFile,
  checkNumberIntervened,
  InterveneChat,
  resumeChatbotInChat
} from "../../api/whatsapp_meta/WhatsappMetaApi";

import {
  getWhatsappApiFilterResult,
  getWhatsappApiReportExport,
  getWhatsappApiSearchResult
} from "../../api/whatsapp/WhatsappReportApi";
import {WhatsappMetaBusinessProfile, WhatsappMetaDisplayNameEdit} from "../../models";


const getWhatsappSettingsService = async () => getWhatsappSettings();
const updateWhatsappSettingsService = async (data: WhatsappSettingsUpdateRequest) => updateWhatsappSettings(data);

const createWhatsappTemplateService = async (data: WhatsappTemplateCreateRequest) => createWhatsappTemplate(data);
const updateWhatsappTemplateService = async (data: WhatsappTemplateUpdateRequest, templateId: number) => updateWhatsappTemplate(data, templateId);
const listWhatsappTemplateService = async () => listWhatsappTemplate();
const listMarketingWhatsappTemplateService = async () => listMarketingWhatsappTemplate();
const listWhatsappTemplateGettingStartedService = async () => listWhatsappTemplateGettingStarted();
const getWhatsappTemplateService = async (templateName: string, data: {"language": string}) => getWhatsappTemplate(templateName, data);
const deleteWhatsappTemplateService = async (data: WhatsappTemplateDeleteRequest) => deleteWhatsappTemplate(data);

const getWhatsappApiSearchResultService = async (key: string, data: WhatsappApiReportFilterRequest) => getWhatsappApiSearchResult(key, data);
const getWhatsappApiFilterResultService = async (data: WhatsappApiReportFilterRequest) => getWhatsappApiFilterResult(data);
const getWhatsappApiReportExportService = async (data: WhatsappApiReportExportRequest) => getWhatsappApiReportExport(data);

const registerWhatsappOriginatorService = async (data: WhatsappOriginatorRegisterRequest) => sendWhatsappOriginatorRegisterRequest(data);
const whatsappShowInterestService = async () => sendWhatsappShowInterestRequest();
const getWhatsappOnboardingService = async () => getOnboardingDetails();

const listWhatsappContactService = async () => listWhatsappContact();
const getWhatsappPricingService = async () => getWhatsappPricing();
const getWhatsappSubscriptionService = async () => getWhatsappSubscription();

const saveWhatsappTokenService = async (data: { "token": string }) => saveWhatsappToken(data);

const onboardWhatsappBusinessAccountService = async (data: WhatsappMetaOnboardRequest) => whatsappMetaOnboarding(data);

const listWhatsappMetaRegisteredNumbersService = async () => listWhatsappMetaRegisteredNumbers();
const getWhatsappMetaRegisteredNumbersService = async (number: string) => getWhatsappMetaRegisteredNumbers(number);

const listWhatsappMetaTemplateService = async (wabaId: string) => listWhatsappMetaTemplate(wabaId);

const listWhatsappMetaMarketingTemplateService = async (number: string) => listWhatsappMetaMarketingTemplate(number);
const listWhatsappMetaAuthenticationTemplateService = async (number: string) => listWhatsappMetaAuthenticationTemplate(number);

const createWhatsappMetaTemplateService = async (data: WhatsappTemplateCreateRequest) => createWhatsappMetaTemplate(data);

const updateWhatsappMetaTemplateService = async (data: WhatsappTemplateUpdateRequest, templateId: number) => updateWhatsappMetaTemplate(data, templateId);

const deleteWhatsappMetaTemplateService = async (data: WhatsappTemplateDeleteRequest) => deleteWhatsappMetaTemplate(data);

const listWhatsappMetaWabaIdService = async () => listWhatsappMetaWabaId();

const uploadResumableFileService = async (file: any) => {
  const data = new FormData();
  data.append('file', file);
  return uploadResumableFile(data);
};

const uploadMediaFileService = async (originator: any, file: any) => {
  const data = new FormData();
  data.append('file', file);
  return uploadMediaFile(originator, data);
};

const getOriginatorDetailsService = async (originator?:string) => getOriginator(originator);

const getWhatsappMetaBusinessProfileService = async (number: string) => getWhatsappMetaBusinessProfile(number);

const updateWhatsappMetaBusinessProfileService = async (ContactNumber: string | undefined, data: WhatsappMetaBusinessProfile) => updateWhatsappMetaBusinessProfile(ContactNumber, data);
const listWhatsappChatService = async (number: string, pageNumber:number, pageSize:number, searchQuery:string) => listWhatsappChatsByPhoneNumber(number, pageNumber, pageSize, searchQuery);
const listWhatsappChatMessagesService = async (brandNumber: string, customerNumber: string, pageNumber: number, abortSignal: AbortSignal) => listWhatsappChatMessagesByPhoneNumber(brandNumber, customerNumber, pageNumber, abortSignal);
const updateWhatsappMetaDisplayNameService = async (ContactNumber: string | undefined, data: WhatsappMetaDisplayNameEdit) => updateWhatsappMetaDisplayName(ContactNumber, data);
const getWhatsappChatExportService = async (brandNumber: string, customerNumber: string) => getWhatsappChatExport(brandNumber, customerNumber);
const fetchWhatsappMediaFileService = async (mediaId: string, onDownloadProgress: (progressEvent: ProgressEvent) => void) => fetchWhatsappMediaFile(mediaId, onDownloadProgress);
const checkNumberIntervenedService = async (registeredNumber: string, customerNumber: string) => checkNumberIntervened(registeredNumber, customerNumber);
const InterveneChatService = async (data: InterveneInboxRequest) => InterveneChat(data);
const resumeChatbotInChatService = async (registeredNumber: string, customerNumber: string) => resumeChatbotInChat(registeredNumber, customerNumber);
export {
  getWhatsappSettingsService,
  updateWhatsappSettingsService,
  createWhatsappTemplateService,
  updateWhatsappTemplateService,
  listWhatsappTemplateService,
  listMarketingWhatsappTemplateService,
  deleteWhatsappTemplateService,
  getWhatsappApiSearchResultService,
  getWhatsappApiFilterResultService,
  getWhatsappApiReportExportService,
  registerWhatsappOriginatorService,
  whatsappShowInterestService,
  getWhatsappOnboardingService,
  listWhatsappContactService,
  listWhatsappTemplateGettingStartedService,
  getWhatsappPricingService,
  getWhatsappTemplateService,
  getWhatsappSubscriptionService,
  saveWhatsappTokenService,
  onboardWhatsappBusinessAccountService,
  listWhatsappMetaRegisteredNumbersService,
  createWhatsappMetaTemplateService,
  deleteWhatsappMetaTemplateService,
  uploadResumableFileService,
  listWhatsappMetaWabaIdService,
  listWhatsappMetaTemplateService,
  updateWhatsappMetaTemplateService,
  listWhatsappMetaMarketingTemplateService,
  listWhatsappMetaAuthenticationTemplateService,
  getOriginatorDetailsService,
  uploadMediaFileService,
  getWhatsappMetaRegisteredNumbersService,
  getWhatsappMetaBusinessProfileService,
  updateWhatsappMetaBusinessProfileService,
  listWhatsappChatService,
  listWhatsappChatMessagesService,
  updateWhatsappMetaDisplayNameService,
  getWhatsappChatExportService,
  fetchWhatsappMediaFileService,
  checkNumberIntervenedService,
  InterveneChatService,
  resumeChatbotInChatService
};
